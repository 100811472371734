import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { Button, Col, ConfigProvider, Empty, Row, Space, Tooltip, Typography } from 'antd';
import I18N from '@/i18n';
import ProTable from '@ant-design/pro-table';
import type { Session } from '@/models/windowSync';
import classNames from 'classnames';
import styles from '@/pages/ShopManage/WindowSync/index.less';
import SortTitle from '@/components/Sort/SortTitle';
import PlatformCateIcon from '@/components/Common/ShopCategorySelector/PlatformCateIcon';
import Placeholder from '@/components/Common/Placeholder';
import { addEventListener, sendAsync } from '@/utils/ElectronUtils';
import IconFontIcon from '@/components/Common/IconFontIcon';
import pMinDelay from 'p-min-delay';
import { scrollProTableOptionFn } from '@/mixins/table';
import DMConfirm from '@/components/Common/DMConfirm';
import { DndContext } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined } from '@ant-design/icons';
import { useModel } from '@@/plugin-model/useModel';
import useWindowSync from '@/hooks/useWindowSync';
import useCurrentTeam from '@/hooks/useCurrentTeam';
import { createPortal } from 'react-dom';

type Props = {
  footer?: () => any;
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const SessionRow: React.FC<RowProps> = (props) => {
  const { children, ...restProps } = props;
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: String(restProps['data-row-key']),
  });

  const style: React.CSSProperties = {
    ...restProps.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...restProps} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'shop,name') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <Row wrap={false} gutter={8}>
                <Col flex="0 0 auto">
                  <MenuOutlined
                    style={{ touchAction: 'none', cursor: 'move' }}
                    {...listeners}
                    title={I18N.t('可拖拽移动窗口顺序')}
                  />
                </Col>
                <Col flex="1" style={{ overflow: 'hidden' }}>
                  <Row wrap={false} gutter={8}>
                    <Col flex="0 0 auto">
                      <PlatformCateIcon
                        platformName={child.props.record?.shop?.platform?.name ?? 'Other'}
                      />
                    </Col>
                    <Col flex="1" style={{ overflow: 'hidden' }}>
                      <Typography.Text ellipsis title={child.props.record?.shop?.name}>
                        {child.props.record?.shop?.name}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

/**
 * 已打开的分身
 * @param props
 * @constructor
 */
const OpeningShopListTable: React.FC<Props> = (props) => {
  const { footer } = props;
  const { team } = useCurrentTeam();
  const [closingRowIds, setClosingRowIds] = useState<number[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const {
    setSessions,
    order,
    changeOrder,
    sessionsSorted,
    controlled,
    master,
    follows,
    selectedRows,
    setSelectedRows,
  } = useModel('windowSync');
  const { fetchGcDetail, beforeOpen, startSync, loadingModalRef } = useWindowSync();
  const downloadedConfirmVisibleRef = useRef(false);
  const windowAlignRef = useRef<any>(null);
  const initialSessionIds = useRef<number[] | null>(null);

  const fetchSessions = useCallback(() => {
    sendAsync('browser.sync.list', { teamId: team.id }).then((list: Session[]) => {
      let _list = list.filter((item) => !item.teamId || item.teamId === team.id);
      if (order.key === 'custom') {
        _list = _list.sort((a, b) => {
          const aIdx = sessionsSorted.findIndex((v) => v.sessionId === a.sessionId);
          const bIdx = sessionsSorted.findIndex((v) => v.sessionId === b.sessionId);
          const aOrder = aIdx === -1 ? 999 : aIdx;
          const bOrder = bIdx === -1 ? 999 : bIdx;
          return aOrder - bOrder;
        });
      }
      if (!initialSessionIds.current) {
        initialSessionIds.current = _list.map((item) => item.sessionId);
        setSelectedRows(_list);
      } else {
        setSelectedRows((prev) => {
          return _list.filter((row) => {
            if (!initialSessionIds.current?.includes(row.sessionId!)) {
              // 新的会话，默认选中
              return true;
            }
            return prev.some((item) => item.sessionId === row.sessionId);
          });
        });
      }
      setSessions(_list);
      setClosingRowIds((prev) => {
        return prev.filter((id) => _list.some((item) => item.sessionId === id));
      });
    });
  }, [team.id]);

  useEffect(() => {
    fetchSessions();
    fetchGcDetail();
    const remove = addEventListener('browser.sync.list.update', () => {
      fetchSessions();
      fetchGcDetail();
    });
    return () => {
      remove();
    };
  }, [fetchGcDetail, fetchSessions]);

  const sessionIdsSorted = useMemo(() => sessionsSorted.map((s) => s.sessionId).join(','), []);

  useEffect(() => {
    sendAsync('browser.sync.update.order', {
      teamId: team.id,
      order: sessionIdsSorted.split(',').map((v) => Number(v)),
    });
  }, [sessionIdsSorted, team.id]);

  useEffect(() => {
    const remove = addEventListener('browser.sync.download.progress', (evt, data) => {
      const { status, percentage, message } = data;
      if (status === 'error') {
        loadingModalRef.current?.destroy();
        DMConfirm({
          type: 'error',
          title: I18N.t('群控程序下载失败'),
          content: message,
        });
      } else if (percentage === '100.00' && !downloadedConfirmVisibleRef.current) {
        loadingModalRef.current?.destroy();
        downloadedConfirmVisibleRef.current = true;
        DMConfirm({
          iconType: 'success',
          title: I18N.t('群控程序下载成功'),
          okText: I18N.t('开启群控'),
          onOk() {
            startSync((layout) => {
              windowAlignRef.current?.changeLayout(layout);
            });
            downloadedConfirmVisibleRef.current = false;
          },
          onCancel: () => {
            downloadedConfirmVisibleRef.current = false;
          },
        });
      } else {
        loadingModalRef.current?.update({
          content: `${I18N.t('已完成')}${data.percentage}%`,
        });
      }
    });
    return () => {
      remove();
    };
  }, [startSync, fetchSessions, loadingModalRef, sessionsSorted]);
  const count = useMemo(() => {
    return selectedRows.length || 0;
  }, [selectedRows]);

  return (
    <>
      <DndContext
        modifiers={[({ transform }) => ({ ...transform, x: 0 })]}
        onDragStart={({ active }) => {
          if (!active) {
            return;
          }
          setActiveId(active.id);
        }}
        onDragEnd={({ over }) => {
          const activeIndex = sessionsSorted.findIndex((s) => s.sessionId === Number(activeId));
          setActiveId(null);
          if (over) {
            const overIndex = sessionsSorted.findIndex((s) => s.sessionId === Number(over.id));
            if (activeIndex !== overIndex) {
              const arr = arrayMove(sessionsSorted, activeIndex, overIndex);
              changeOrder({ key: 'custom', ascend: true });
              setSessions(arr);
            }
          }
        }}
        onDragCancel={() => setActiveId(null)}
      >
        <SortableContext items={sessionsSorted?.map((item) => String(item.sessionId!))}>
          <ConfigProvider
            renderEmpty={() => {
              return (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={I18N.t('您当前没有打开的分身窗口')}
                />
              );
            }}
          >
            <ProTable<Session>
              rowKey="sessionId"
              rowSelection={{
                selectedRowKeys: selectedRows?.map((item) => item.sessionId!),
                onChange(ks, rs) {
                  setSelectedRows(rs);
                },
              }}
              dataSource={sessionsSorted}
              components={{
                body: {
                  row: SessionRow,
                },
              }}
              onRow={(record) => {
                const isMaster = record.sessionId === master;
                const isFollows = follows.includes(record.sessionId);
                return {
                  className: classNames(
                    { [styles.isMaster]: isMaster },
                    { [styles.isFollows]: isFollows },
                  ),
                };
              }}
              columns={[
                {
                  dataIndex: ['shop', 'name'],
                  title: (
                    <SortTitle
                      orderKey="name"
                      order={order}
                      onSort={changeOrder}
                      label={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            whiteSpace: 'nowrap',
                            flexWrap: 'nowrap',
                          }}
                        >
                          <span>{I18N.t('分身名称')}</span>
                          {count > 0 && (
                            <Typography.Link>
                              {I18N.t('（已选')}
                              {count.toLocaleString()}）
                            </Typography.Link>
                          )}
                        </div>
                      }
                    />
                  ),

                  ellipsis: true,
                  render: (text, record) => {
                    return (
                      <Row wrap={false} gutter={8}>
                        <Col flex="0 0 auto">
                          <PlatformCateIcon platformName={record.shop.platform?.name ?? 'Other'} />
                        </Col>
                        <Col flex="1">
                          <Typography.Text ellipsis>{text}</Typography.Text>
                        </Col>
                      </Row>
                    );
                  },
                },
                {
                  dataIndex: 'startTime',
                  title: (
                    <SortTitle
                      orderKey="startTime"
                      order={order}
                      onSort={changeOrder}
                      label={I18N.t('打开时间')}
                    />
                  ),

                  width: 100,
                  valueType: 'time',
                },
                {
                  dataIndex: 'primary',
                  title: (
                    <SortTitle
                      orderKey="status"
                      order={order}
                      onSort={changeOrder}
                      label={I18N.t('状态')}
                    />
                  ),

                  width: 100,
                  render(_, record) {
                    const isMaster = record.sessionId === master;
                    const isFollows = follows.includes(record.sessionId);
                    const isRPA = !!record.rpaFlowId;
                    if (isMaster) {
                      return isRPA ? I18N.t('主控（RPA）') : I18N.t('主控');
                    }
                    if (isFollows) {
                      return isRPA ? I18N.t('被控（RPA）') : I18N.t('被控');
                    }
                    if (isRPA) {
                      return 'RPA';
                    }
                    return <Placeholder />;
                  },
                },
                {
                  title: I18N.t('操作'),
                  width: 70,
                  valueType: 'option',
                  render(_, record) {
                    const { shop } = record;
                    const isLoading = closingRowIds.includes(record.sessionId);
                    return (
                      <Space>
                        <Tooltip title={I18N.t('激活窗口')}>
                          <Typography.Link
                            type="success"
                            onClick={() => {
                              sendAsync('browser.sync.focus', [shop.id]);
                            }}
                          >
                            <IconFontIcon iconName={'jihuochuangkou'} />
                          </Typography.Link>
                        </Tooltip>
                        {isLoading ? (
                          <Typography.Link type={'danger'}>
                            <IconFontIcon iconName={'loading_24'} spin />
                          </Typography.Link>
                        ) : (
                          <Tooltip title={I18N.t('关闭窗口')}>
                            <Typography.Link
                              type={'danger'}
                              onClick={() => {
                                setClosingRowIds((prev) => [...prev, record.sessionId]);
                                pMinDelay(sendAsync('browser.sync.close', [shop.id]), 1000).then(
                                  () => {
                                    setClosingRowIds((prev) =>
                                      prev.filter((id) => id !== record.sessionId),
                                    );
                                  },
                                );
                              }}
                            >
                              <IconFontIcon iconName={'Close-Circle_24'} />
                            </Typography.Link>
                          </Tooltip>
                        )}
                      </Space>
                    );
                  },
                },
              ]}
              {...scrollProTableOptionFn({
                alwaysShowFooter: !!footer,
                rowKey: 'sessionId',
                pagination: false,
                footer,
              })}
            />
          </ConfigProvider>
        </SortableContext>
      </DndContext>
      {document.querySelector('#window-sync-toolbox-footer') &&
        createPortal(
          <>
            <Button
              type="primary"
              disabled={selectedRows.length === 0}
              onClick={() => {
                beforeOpen(I18N.t('最小化')).then(() => {
                  // 最小化
                  sendAsync(
                    'browser.sync.minimizeWin',
                    selectedRows.map((item) => item.sessionId),
                  );
                });
              }}
            >
              <span>{I18N.t('最小化所选窗口')}</span>
            </Button>
            <Button
              type="primary"
              disabled={selectedRows.length === 0}
              onClick={() => {
                beforeOpen(I18N.t('显示')).then(() => {
                  // 显示
                  sendAsync(
                    'browser.sync.restoreWin',
                    selectedRows.map((item) => item.sessionId),
                  );
                });
              }}
            >
              <span>{I18N.t('激活所选窗口')}</span>
            </Button>
            <Button
              type="primary"
              danger
              disabled={selectedRows.length === 0}
              onClick={() => {
                if (!selectedRows.length) return;
                if (closingRowIds.length > 0) return;
                beforeOpen(I18N.t('关闭')).then(() => {
                  setClosingRowIds(selectedRows.map((r) => r.sessionId));
                  pMinDelay(
                    sendAsync(
                      'browser.sync.close',
                      selectedRows.map((r) => r.shop.id),
                    ),
                    1000,
                  ).then(() => {
                    setClosingRowIds([]);
                  });
                });
              }}
              loading={!!closingRowIds.length}
            >
              <span>{I18N.t('关闭所选窗口')}</span>
            </Button>
          </>,
          document.querySelector('#window-sync-toolbox-footer')!,
        )}
    </>
  );
};

export default OpeningShopListTable;

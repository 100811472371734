import type { ResourceType } from '@/types/ResourceType';
import { useCallback, useEffect, useRef, useState } from 'react';
import { tagsGet } from '@/services/api-ShopAPI/TagController';
import EventEmitter from 'events';
import { SkipErrorNotifyOption } from '@/utils/utils';

const emitter = new EventEmitter();
emitter.setMaxListeners(9999);

const Tags = {
  Ip: null,
  Shop: null,
};
type model = {
  data: API.TagVo[] | null;
  run: () => void;
  loading: boolean;
};
export const clearOnTeamChange = () => {
  Object.keys(Tags).forEach((key) => {
    Tags[key] = null;
  });
};

export default function useTags(type: ResourceType, adapter?: (data: model) => any) {
  const [tags, setTags] = useState(Tags[type]);
  const fetch = useCallback(() => {
    tagsGet({ resourceType: type, count: false }, SkipErrorNotifyOption).then((res) => {
      Tags[type] = res.data;
      emitter.emit(`UPDATE_EVENT_${type}`, res.data);
    });
  }, [type]);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    emitter.addListener(`UPDATE_EVENT_${type}`, setTags);
    return () => {
      mounted.current = false;
      emitter.removeListener(`UPDATE_EVENT_${type}`, setTags);
    };
  }, [type]);
  useEffect(() => {
    if (mounted.current) {
      if (!Tags[type]) {
        fetch();
      }
    }
  }, [fetch, type]);
  const model = {
    data: tags,
    run: fetch,
    loading: !tags,
  };
  if (adapter) {
    return adapter(model);
  }
  return model;
}

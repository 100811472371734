import I18N from '@/i18n';
import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Alert, Col, Form, InputNumber, Row, Select, Space, Switch, Table, Tooltip } from 'antd';
import { FormBaseProps } from '@/components/UserCenter/UserSetting';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import FormItem from '@/components/UserCenter/components/FormItem';
import {
  emitEvent,
  getAppVersion,
  getInstalledKernelInfo,
  InstalledKernelInfo,
  isElectron,
  sendAsync,
} from '@/utils/ElectronUtils';
import { getSysPresSettings, setSysPresSettings } from '@/i18n/I18N';
import IconFontIcon from '@/components/Common/IconFontIcon';
import DMConfirm, { DMLoading } from '@/components/Common/DMConfirm';
import { useLocalFrontendProxySettingsModal } from '@/components/UserCenter/components/LocalFrontendProxySettingsModal';

/**
 * 浏览器内核
 * @param props
 * @param ref
 * @constructor
 */
function BrowserKernelForm(props: FormBaseProps, ref: Ref<unknown> | undefined) {
  const [kernelInfo, setKernelInfo] = useState<InstalledKernelInfo[]>(getInstalledKernelInfo());
  const [loading, setLoading] = useState(false);

  return (
    <DMFormItemContext.Provider value={{ labelWidth: 150 }}>
      <Alert
        showIcon
        message={I18N.t('根据不同的浏览器指纹的UA版本，会使用不同版本的花漾浏览器内核')}
        style={{ marginTop: 16, marginBottom: 16 }}
      />
      {parseFloat(getAppVersion()) > 10.2 && (
        <Table
          size="small"
          dataSource={kernelInfo}
          scroll={{ y: 330 }}
          pagination={false}
          loading={loading}
          columns={[
            {
              title: I18N.t('内核版本'),
              dataIndex: 'version',
              width: 100,
            },
            {
              title: I18N.t('安装位置'),
              dataIndex: 'path',
              ellipsis: true,
            },
            {
              title: I18N.t('操作'),
              key: 'opt',
              width: 60,
              render: (dom, record) => {
                return (
                  <Space>
                    <Tooltip title={I18N.t('删除')}>
                      <a
                        onClick={async () => {
                          const chromiums = await sendAsync('get-opening-chromiums');
                          if (chromiums.some((c) => c.kernelPath === record.path)) {
                            DMConfirm({
                              type: 'info',
                              title: I18N.t('该浏览器正处于打开的状态'),
                              content: I18N.t('请关闭相应的浏览器后再进行删除'),
                            });
                          } else {
                            DMConfirm({
                              title: I18N.t('确定要删除选中的浏览器吗？'),
                              content: I18N.t('花漾客户端同时支持不同内核版本的浏览器'),
                              onOk: () => {
                                setLoading(true);
                                sendAsync('uninstall-browser-kernel', {
                                  buildNumber: record.buildNumber,
                                }).finally(() => {
                                  setKernelInfo(getInstalledKernelInfo());
                                  setLoading(false);
                                });
                              },
                            });
                          }
                        }}
                      >
                        <IconFontIcon iconName="Trash_24" />
                      </a>
                    </Tooltip>
                    <Tooltip title={I18N.t('文件所在位置')}>
                      <a
                        onClick={() => {
                          emitEvent('open-path', {
                            path: record.path,
                          });
                        }}
                      >
                        <IconFontIcon iconName="xinjianyeqian_24" />
                      </a>
                    </Tooltip>
                  </Space>
                );
              },
            },
          ]}
        />
      )}
    </DMFormItemContext.Provider>
  );
}

export default forwardRef(BrowserKernelForm);

// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 获取App版本配置信息 GET /api/meta/app/versionConfigs */
export async function metaAppVersionConfigsGet(options?: { [key: string]: any }) {
  return request<API.WebResultAppVersionConfig>('/api/meta/app/versionConfigs', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 支持的云厂商列表 GET /api/meta/cloud/providers */
export async function metaCloudProvidersGet(options?: { [key: string]: any }) {
  return request<API.WebResultListCloudProviderVo>('/api/meta/cloud/providers', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取云厂商的区域列表 GET /api/meta/cloud/regions */
export async function metaCloudRegionsGet(
  params: {
    // query
    /** provider */
    provider?:
      | 'aliyun'
      | 'aws'
      | 'aws_cn'
      | 'azure'
      | 'baidu'
      | 'baoliannet'
      | 'bluevps'
      | 'dmit'
      | 'ecloud10086'
      | 'googlecloud'
      | 'huawei'
      | 'huayang'
      | 'huoshan'
      | 'jdbox'
      | 'jdcloud'
      | 'jdeip'
      | 'lan'
      | 'oracle'
      | 'other'
      | 'qcloud'
      | 'raincloud'
      | 'ucloud'
      | 'vlcloud'
      | 'vps';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListCloudProviderVo>('/api/meta/cloud/regions', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取花瓣类型信息 GET /api/meta/creditTypes */
export async function metaCreditTypesGet(options?: { [key: string]: any }) {
  return request<API.WebResultListCreditTypeVo>('/api/meta/creditTypes', {
    method: 'GET',
    ...(options || {}),
  });
}

/** getExchangeRate GET /api/meta/exchange/rate */
export async function metaExchangeRateGet(options?: { [key: string]: any }) {
  return request<API.WebResultExchangeRateVo>('/api/meta/exchange/rate', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查询运营配置 GET /api/meta/sysOpConfig */
export async function metaSysOpConfigGet(options?: { [key: string]: any }) {
  return request<API.WebResultSystemOpConfig>('/api/meta/sysOpConfig', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取TK套餐参数类型 GET /api/meta/tk/pack/paramDef */
export async function metaTkPackParamDefGet(options?: { [key: string]: any }) {
  return request<API.WebResultListTkPackParamVo>('/api/meta/tk/pack/paramDef', {
    method: 'GET',
    ...(options || {}),
  });
}

import I18N from '@/i18n';
import React, { CSSProperties, useCallback, useEffect, useMemo } from 'react';
import { Button, Modal, Typography } from 'antd';
import Lottie from 'react-lottie';
import { history } from 'umi';
import styles from './index.less';
import lottieImg from '@/components/LeftMenu/lottie-assets/attention.json';
import lottieImg2 from '@/components/LeftMenu/lottie-assets/announcement.json';
import IconFontIcon from '@/components/Common/IconFontIcon';
import ProTable from '@ant-design/pro-table';
import { scrollProTableOptionFn } from '@/mixins/table';
import { msgCenterMarkHasReadPut } from '@/services/api-MessageCenterAPI/MessageCenterController';
import type { ModalFuncProps } from 'antd/lib/modal/Modal';
import { dateFormat, formatMilliseconds, getTeamIdFromUrl } from '@/utils/utils';
import ElectronUtils from '@/utils/ElectronUtils';
import { marked } from 'marked';
import _ from 'lodash';

type Props = {
  message: API.UserMessageVo;
  goto: (p: string) => void;
  scrollY?: number;
};
type ModalInstance = {
  destroy: () => void;
  update: (configUpdate: ModalFuncProps | ((prevConfig: ModalFuncProps) => ModalFuncProps)) => void;
};
export const CriticalInspectionDetail = (props: Props) => {
  const { message, goto, scrollY } = props;
  const list = JSON.parse(message.parameters);
  const options = useMemo(() => {
    const _option = {
      rowKey(record) {
        if (record.resourceId) {
          return record.resourceId;
        }
        return record?.createTime + record.resourceType;
      },
    };
    if (scrollY) {
      _option.scroll = {
        y: scrollY,
      };
    }
    return _option;
  }, [scrollY]);
  const { createTime } = message;
  const diff = formatMilliseconds(new Date().getTime() - new Date(createTime!).getTime(), {
    compact: true,
  });
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        flex: 1,
        paddingBottom: 12,
        overflow: 'hidden',
      }}
    >
      <div style={{ overflow: 'hidden', flex: 1 }}>
        <ProTable
          size={'small'}
          cardBordered
          dataSource={list}
          columns={[
            {
              dataIndex: 'desc',
              title: I18N.t('注意事项'),
              ellipsis: true,
              render(_, record) {
                const { resourceType, resourceId, resourceName, desc } = record;
                const [prefix = null, suffix = null] = desc.split(resourceName);
                if (['Credit', 'Ip', 'RpaVoucher'].includes(resourceType)) {
                  return (
                    <Typography.Text ellipsis title={desc}>
                      {prefix && <span style={{ marginRight: 4 }}>{prefix}</span>}
                      <Typography.Link
                        onClick={() => {
                          if (resourceType === 'Credit') {
                            goto(`/costManage/overview`);
                          } else if (resourceType === 'Ip') {
                            goto(`/ipManage/all/${resourceId}`);
                          } else {
                            goto(`/rpa/voucher`);
                          }
                        }}
                      >
                        {resourceName}
                      </Typography.Link>
                      {suffix && <span style={{ marginLeft: 4 }}>{suffix}</span>}
                    </Typography.Text>
                  );
                }

                return (
                  <Typography.Text ellipsis title={desc}>
                    {desc}
                  </Typography.Text>
                );
              },
            },
          ]}
          {...scrollProTableOptionFn(options)}
        />
      </div>
      <div>
        {I18N.t('该消息产生于')}
        {diff}
        {I18N.t('前，如果您已处理完毕，请忽略此消息')}
      </div>
    </div>
  );
};

export const AnnouncementDetail = (props: { content?: string }) => {
  const { content } = props;
  const html = useMemo(() => {
    return marked.parse(content as string, {
      mangle: false,
      smartypants: true,
      gfm: true,
      breaks: true,
      sanitize: true,
      xhtml: true,
    });
  }, [content]);
  const __html = useMemo(() => {
    return html
      .replace(/(&lt;)/g, '<')
      .replace(/(&gt;)/g, '>')
      .replace(/(&quot;)/g, "'");
  }, [html]);
  return (
    <div
      style={{ flex: 1, overflow: 'auto', textAlign: 'left', textIndent: '2em' }}
      dangerouslySetInnerHTML={{ __html }}
      onClickCapture={(e) => {
        e.stopPropagation();
        if (e?.target.nodeName === 'A' && e?.target?.href) {
          const url = e?.target?.href;
          if (ElectronUtils.isElectron()) {
            ElectronUtils.emitEvent('open-external-url', {
              url,
            });
          } else {
            window.open(url);
          }
          e.preventDefault();
          return false;
        }
        return true;
      }}
    />
  );
};

/**
 * 关键事项提醒
 * @param props
 * @constructor
 */
const CriticalInspection: React.FC<{ instance: ModalInstance; message: API.UserMessageVo }> = (
  props,
) => {
  const { message, instance } = props;

  const doClose = useCallback(() => {
    instance.destroy();
  }, [instance]);

  const handleOkBtnClick = useCallback(() => {
    doClose();
  }, [doClose]);

  const messageContent = useMemo(() => {
    if (React.isValidElement(message.content)) {
      return (
        <>
          <h2 style={{ textAlign: 'center', marginBottom: 20 }}>{I18N.t('花漾客户端告警')}</h2>
          {message.content}
        </>
      );
    }
    const { messageType, summary, content, createTime } = message;
    if (messageType === 'Critical_Message') {
      return (
        <>
          <h2 style={{ textAlign: 'center', marginBottom: 20 }}>
            {I18N.t('当前团队存在以下事项，需要您的注意')}
          </h2>
          <CriticalInspectionDetail
            message={message}
            goto={(p) => {
              doClose();
              history.push(`/team/${getTeamIdFromUrl()}${p}`);
            }}
          />
        </>
      );
    }
    return (
      <div
        style={{
          flex: 1,
          overflow: 'hidden',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Typography.Text
          ellipsis={{ tooltip: summary }}
          style={{
            fontSize: '1.5em',
          }}
        >
          {summary}
        </Typography.Text>
        <Typography.Text style={{ color: '#999', textAlign: 'center', padding: '6px 0' }}>
          {dateFormat(new Date(createTime!))}
        </Typography.Text>
        <AnnouncementDetail
          content={content}
          style={{
            textIndent: '2em',
            lineHeight: 1.9,
          }}
        />
      </div>
    );
  }, [doClose, message]);

  useEffect(() => {
    setTimeout(() => {
      if (!message.readTime && message.id) {
        // 标为已读
        msgCenterMarkHasReadPut({ ids: message.id! });
      }
    }, 300);
  }, [message.id, message.readTime]);
  const content = useMemo(() => {
    const { messageType } = message;
    const title = messageType === 'Critical_Message' ? I18N.t('关键事项检查') : I18N.t('运营公告');
    const animationData = messageType === 'Critical_Message' ? lottieImg : lottieImg2;
    const style: CSSProperties = {
      background: messageType === 'Critical_Message' ? 'rgb(237, 97, 77)' : '#1677ff',
    };
    return (
      <>
        <div className={styles.leftPart} style={style}>
          <div className={styles.activityIcon}>
            <Lottie
              style={messageType === 'Critical_Message' ? undefined : { transform: 'scale(0.8)' }}
              options={{
                animationData,
              }}
            />
          </div>
          <div className={styles.activityName}>
            <h2>{title}</h2>
          </div>
        </div>
        <div className={styles.rightPart}>
          {messageContent}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" onClick={handleOkBtnClick}>
              {I18N.t('我知道了')}
            </Button>
          </div>
        </div>
      </>
    );
  }, [handleOkBtnClick, message, messageContent]);

  return <div className={styles.wrap}>{content}</div>;
};

let instance: ModalInstance | undefined;
const fn = (message: Omit<API.UserMessageVo, 'content'> & { content: any }): (() => void) => {
  if (instance) {
    instance.update({
      modalRender() {
        return (
          <div className={styles.modalClass}>
            <Button className="ant-modal-close">
              <IconFontIcon
                iconName="guanbi_24"
                onClick={() => {
                  instance?.destroy();
                }}
              />
            </Button>
            <CriticalInspection message={message} instance={instance} />
          </div>
        );
      },
    });
  } else {
    instance = Modal.info({
      title: false,
      icon: false,
      centered: true,
      closable: true,
      style: {
        padding: 0,
      },
      afterClose() {
        instance = undefined;
      },
      width: 800,
      modalRender() {
        return (
          <div className={styles.modalClass}>
            <Button className="ant-modal-close">
              <IconFontIcon
                iconName="guanbi_24"
                onClick={() => {
                  instance?.destroy();
                }}
              />
            </Button>
            <CriticalInspection message={message} instance={instance} />
          </div>
        );
      },
    });
  }
  return () => {
    instance?.destroy();
  };
};
const openCriticalInspection = _.debounce(fn, 500);

export default openCriticalInspection;

import { useCallback, useEffect, useMemo, useState } from 'react';
import EventEmitter from 'events';

let _list: API.PlatformVo[] = [];
const event = new EventEmitter();
event.setMaxListeners(999);

export function setPlatformList(data: API.PlatformVo[]) {
  _list = [...data];
  event.emit('update', data);
}
export default function usePlatforms(
  category?: API.PlatformVo['category'][],
  showHiddenPlatform?: boolean,
) {
  const [list, setList] = useState([..._list]);
  useEffect(() => {
    event.on('update', setList);
    return () => {
      event.off('update', setList);
    };
  }, []);

  return useMemo(() => {
    let innerList = !category ? list : list.filter((group) => category.includes(group.category));
    if (!showHiddenPlatform) {
      innerList = innerList.filter((item) => item.show);
    }
    const otherIndex = innerList.findIndex((item) => item.category === 'Other');
    if (otherIndex !== -1) {
      const other = innerList[otherIndex];
      innerList.splice(otherIndex, 1);
      innerList.push(other);
    }
    return innerList;
  }, [category, list]);
}
export function usePlatformCallback() {
  const platforms = usePlatforms();
  return useCallback(
    (platformId: number) => {
      let platform: API.ShopPlatformDetail | undefined;
      for (let i = 0; i < platforms.length; i++) {
        const group = platforms[i];
        const index = group.endpoints?.findIndex((item) => item.id === platformId);
        if (index !== -1) {
          platform = group.endpoints?.[index!];
          break;
        }
      }
      return platform;
    },
    [platforms],
  );
}

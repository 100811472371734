import I18N from '@/i18n';
import type { Ref } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Form, Input, Select, Space } from 'antd';
import type { FormBaseProps } from '@/components/UserCenter/UserSetting';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import { isElectron } from '@/utils/ElectronUtils';
import { getSysPresSettings, setSysPresSettings } from '@/i18n/I18N';
import { ProFormColorPicker } from '@ant-design/pro-form';

/**
 * 窗口群控
 * @param props
 * @param ref
 * @constructor
 */
function BrowserGCForm(
  props: FormBaseProps & {
    hideWinSyncPauseKey?: boolean;
  },
  ref: Ref<unknown> | undefined,
) {
  const { editing, hideWinSyncPauseKey = false } = props;
  const [v, update] = useState(0);
  const [formIns] = Form.useForm();
  const sysPresSettings = useMemo(() => getSysPresSettings(), []);

  useEffect(() => {
    update(v + 1);
  }, []);
  useImperativeHandle(ref, () => {
    return {
      submit: async () => {
        await formIns.validateFields();
        const fieldsValue = formIns.getFieldsValue();
        const sysPresSettings = getSysPresSettings();
        await setSysPresSettings({ ...sysPresSettings, ...fieldsValue });
      },
      resetFields() {
        formIns.resetFields();
      },
    };
  });

  return (
    <DMFormItemContext.Provider value={{ labelWidth: 150 }}>
      <Form
        form={formIns}
        requiredMark={false}
        initialValues={{
          winSyncRunningColor: '#FF0000',
          winSyncPauseColor: '#00FF00',
          ...sysPresSettings!,
        }}
      >
        {isElectron() && (
          <DMFormItem
            label={I18N.t('群控快捷键')}
            hidden={hideWinSyncPauseKey}
            tooltip={I18N.t('群控启动/暂停的快捷键')}
          >
            {editing ? (
              <DMFormItem name="winSyncPauseKey" noStyle>
                <WinSyncPauseKey />
              </DMFormItem>
            ) : (
              <span>
                {(formIns.getFieldValue('winSyncPauseKey') || 'Ctrl+Shift+S')
                  .split('+')
                  .join(' + ')}
              </span>
            )}
          </DMFormItem>
        )}
        {isElectron() && (
          <DMFormItem label={I18N.t('群控主窗口边框')}>
            <Space size={50}>
              <Space size={8}>
                <span>{I18N.t('群控时颜色')}</span>
                <ProFormColorPicker
                  name="winSyncRunningColor"
                  noStyle
                  readonly={!editing}
                  fieldProps={{ disableAlpha: true }}
                />
              </Space>
              <Space size={8}>
                <span>{I18N.t('暂停时颜色')}</span>
                <ProFormColorPicker
                  name="winSyncPauseColor"
                  noStyle
                  readonly={!editing}
                  fieldProps={{ disableAlpha: true }}
                />
              </Space>
            </Space>
          </DMFormItem>
        )}
      </Form>
    </DMFormItemContext.Provider>
  );
}

function WinSyncPauseKey({ value, onChange }: any) {
  const [, _modKey = 'Shift', _charKey = 'S'] = (value || '').split('+');
  const [modKey, setModKey] = useState(_modKey);
  const [charKey, setCharKey] = useState(_charKey);

  useEffect(() => {
    onChange(`Ctrl+${modKey}+${charKey}`);
  }, [charKey, modKey, onChange]);

  return (
    <Space>
      <span>Ctrl</span>
      <span>+</span>
      <Select
        value={modKey}
        onChange={(v) => {
          setModKey(v);
        }}
      >
        <Select.Option value="Shift">Shift</Select.Option>
        <Select.Option value="Alt">Alt</Select.Option>
      </Select>
      <span>+</span>
      <Input
        value={charKey}
        readOnly
        style={{ width: 50, textAlign: 'center' }}
        onKeyDown={(e) => {
          if (/^[a-z0-9]$/.test(e.key)) {
            setCharKey(e.key.toUpperCase());
          }
        }}
      />
    </Space>
  );
}

export default forwardRef(BrowserGCForm);

import I18N from '@/i18n';
import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Col, Form, InputNumber, Row, Select, Space, Switch, Typography } from 'antd';
import { FormBaseProps } from '@/components/UserCenter/UserSetting';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import FormItem from '@/components/UserCenter/components/FormItem';
import { isElectron } from '@/utils/ElectronUtils';
import { getSysPresSettings, setSysPresSettings } from '@/i18n/I18N';
import { useLocalFrontendProxySettingsModal } from '@/components/UserCenter/components/LocalFrontendProxySettingsModal';

export function getBrowserLang(langCode: string) {
  return {
    auto: I18N.t('跟随客户端语言设置'),
    autoIp: I18N.t('跟随IP的语言设置'),
    'zh-CN': I18N.t('简体中文'),
    'zh-TW': I18N.t('繁体中文（台湾）'),
    'zh-HK': I18N.t('繁体中文（香港）'),
    en: 'English',
    'en-US': 'English',
    'ko-KR': I18N.t('韩语-한국어'),
    'th-TH': I18N.t('泰语-ไทย'),
    'id-ID': I18N.t('印度尼西亚语-Indonesia'),
    'tl-PH': I18N.t('菲律宾语-Filipino'),
    'vi-VN': I18N.t('越南语-TiếngViệt'),
    'ja-JP': I18N.t('日语-日本語'),
    'ms-MY': I18N.t('马来语-Melayu'),
  }[langCode];
}

export const browserLangList = [
  'zh-CN',
  'zh-TW',
  'zh-HK',
  'en-US',
  'ko-KR',
  'th-TH',
  'id-ID',
  'tl-PH',
  'vi-VN',
  'ja-JP',
  'ms-MY',
];

function LocalFrontendProxyCon({ form, editing }: any) {
  const showLocalFrontendProxySettingsModal = useLocalFrontendProxySettingsModal();
  const [checked, setChecked] = useState(form.getFieldValue('localFrontendProxyEnabled') ?? false);

  useEffect(() => {
    form.setFieldsValue({
      localFrontendProxyEnabled: checked,
    });
  }, [checked, form]);

  const modeLabel =
    form.getFieldValue('localFrontendProxyMode') === 'system'
      ? I18N.t('系统代理')
      : form.getFieldValue('localFrontendProxyUrl');

  if (editing) {
    return (
      <Space>
        <Switch
          checked={checked}
          checkedChildren={I18N.t('开启')}
          unCheckedChildren={I18N.t('关闭')}
          onChange={(v) => {
            if (v) {
              showLocalFrontendProxySettingsModal({
                localFrontendProxyMode: form.getFieldValue('localFrontendProxyMode'),
                localFrontendProxyUrl: form.getFieldValue('localFrontendProxyUrl'),
                onSubmit: (values) => {
                  form.setFieldsValue(values);
                  setChecked(true);
                },
              });
            } else {
              setChecked(false);
            }
          }}
        />
        {checked && (
          <Typography.Link
            ellipsis
            style={{ maxWidth: 480 }}
            onClick={() => {
              showLocalFrontendProxySettingsModal({
                localFrontendProxyMode: form.getFieldValue('localFrontendProxyMode'),
                localFrontendProxyUrl: form.getFieldValue('localFrontendProxyUrl'),
                onSubmit: (values) => {
                  form.setFieldsValue(values);
                },
              });
            }}
          >
            （{modeLabel}）
          </Typography.Link>
        )}
      </Space>
    );
  }
  if (checked) {
    return (
      <Space>
        <span>{I18N.t('开启')}</span>
        <Typography.Text ellipsis style={{ maxWidth: 480, color: '#999' }}>
          （{modeLabel}）
        </Typography.Text>
      </Space>
    );
  }
  return <span>{I18N.t('关闭')}</span>;
}

/**
 * 浏览器偏好设置
 * @param props
 * @param ref
 * @constructor
 */
function BrowserPrefForm(props: FormBaseProps, ref: Ref<unknown> | undefined) {
  const { editing } = props;
  const [v, update] = useState(0);
  const [formIns] = Form.useForm();
  const sysPresSettings = useMemo(() => getSysPresSettings(), []);

  useEffect(() => {
    update(v + 1);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      submit: async () => {
        await formIns.validateFields();
        const fieldsValue = formIns.getFieldsValue();
        const sysPresSettings = getSysPresSettings();
        if (isElectron()) {
          await setSysPresSettings({ ...sysPresSettings, ...fieldsValue });
        }
      },
      resetFields() {
        formIns.resetFields();
      },
    };
  });

  return (
    <DMFormItemContext.Provider value={{ labelWidth: 150 }}>
      <Form
        form={formIns}
        requiredMark={false}
        initialValues={{
          ...sysPresSettings!,
          sandbox: sysPresSettings.sandbox ?? true,
          localFrontendProxyEnabled: sysPresSettings.localFrontendProxyEnabled ?? false,
          localFrontendProxyMode: sysPresSettings.localFrontendProxyMode ?? 'system',
          localFrontendProxyUrl: sysPresSettings.localFrontendProxyUrl ?? '',
          openBrowserIntervalSecMin: sysPresSettings.openBrowserIntervalSecMin ?? 2,
          openBrowserIntervalSecMax: sysPresSettings.openBrowserIntervalSecMax ?? 8,
        }}
      >
        <FormItem
          label={I18N.t('浏览器界面语言')}
          name="lang"
          tooltip={I18N.t(
            '花漾指纹浏览器界面使用的语言版本，默认为“跟随客户端设置”，但用户可自行设置，请注意，团队设置中的优先级将高于个人偏好设置',
          )}
        >
          {editing ? (
            <Select>
              {['auto', 'autoIp', ...browserLangList].map((langCode) => (
                <Select.Option key={langCode} value={langCode}>
                  {getBrowserLang(langCode)}
                </Select.Option>
              ))}
            </Select>
          ) : (
            getBrowserLang(formIns.getFieldValue('lang') ?? 'zh-CN')
          )}
        </FormItem>
        <FormItem
          label={I18N.t('浏览器Debug模式')}
          name="devtools"
          valuePropName="checked"
          tooltip={I18N.t(
            '当开启Debug模式时，花漾灵动可通过按F12打开开发者工具DevTools；请注意，团队设置中的优先级将高于个人偏好设置',
          )}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {editing ? (
            <Switch checkedChildren={I18N.t('开启')} unCheckedChildren={I18N.t('关闭')} />
          ) : formIns.getFieldValue('devtools') ? (
            I18N.t('开启')
          ) : (
            I18N.t('关闭')
          )}
        </FormItem>
        <FormItem
          label={I18N.t('浏览器沙盒模式')}
          name="sandbox"
          valuePropName="checked"
          tooltip={I18N.t(
            '在某些情况下，由于操作系统配置、不兼容的硬件驱动程序或其他因素导致启用沙盒模式后无法正常运行浏览器。此时，可以关闭沙盒模式，以解决这些问题',
          )}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {editing ? (
            <Switch checkedChildren={I18N.t('开启')} unCheckedChildren={I18N.t('关闭')} />
          ) : formIns.getFieldValue('sandbox') ?? true ? (
            I18N.t('开启')
          ) : (
            I18N.t('关闭')
          )}
        </FormItem>
        <FormItem
          label={I18N.t('GPU加速')}
          name="gpu"
          valuePropName="checked"
          tooltip={I18N.t('如果出现浏览器闪屏的现象，可通过开启或关闭GPU加速予以缓解')}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {editing ? (
            <Switch checkedChildren={I18N.t('开启')} unCheckedChildren={I18N.t('关闭')} />
          ) : formIns.getFieldValue('gpu') ? (
            I18N.t('开启')
          ) : (
            I18N.t('关闭')
          )}
        </FormItem>
        <DMFormItem
          label={I18N.t('打开浏览器间隔')}
          tooltip={I18N.t(
            '当批量打开多个浏览器时的间隔，默认最小值为2秒，最大值为8秒，可根据客户端设备的性能进行调整',
          )}
        >
          <Row>
            <Col span={12}>
              <Space>
                <span>{I18N.t('最小')}</span>
                <DMFormItem name="openBrowserIntervalSecMin" noStyle>
                  {editing ? (
                    <InputNumber min={0} max={60} />
                  ) : (
                    formIns.getFieldValue('openBrowserIntervalSecMin')
                  )}
                </DMFormItem>
                <span>{I18N.t('秒')}</span>
              </Space>
            </Col>
            <Col span={12}>
              <Space>
                <span>{I18N.t('最大')}</span>
                <DMFormItem name="openBrowserIntervalSecMax" noStyle>
                  {editing ? (
                    <InputNumber min={0} max={60} />
                  ) : (
                    formIns.getFieldValue('openBrowserIntervalSecMax')
                  )}
                </DMFormItem>
                <span>{I18N.t('秒')}</span>
              </Space>
            </Col>
          </Row>
        </DMFormItem>
        <DMFormItem name="localFrontendProxyEnabled" hidden />
        <DMFormItem name="localFrontendProxyMode" hidden />
        <DMFormItem name="localFrontendProxyUrl" hidden />
        <DMFormItem shouldUpdate noStyle>
          {() => (
            <DMFormItem
              label={I18N.t('海外IP加速')}
              tooltip={I18N.t(
                '花漾浏览器直接连接海外IP会面临连接速度慢、不稳定等状况，您可以设置是否使用系统代理连接海外IP，这样会加速海外IP的连接速度',
              )}
            >
              <LocalFrontendProxyCon form={formIns} editing={editing} />
            </DMFormItem>
          )}
        </DMFormItem>
      </Form>
    </DMFormItemContext.Provider>
  );
}

export default forwardRef(BrowserPrefForm);

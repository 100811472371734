import I18N from '@/i18n';
import React, { useCallback, useMemo } from 'react';

import { useModalCaller } from '@/mixins/modal';
import DMModal from '@/components/Common/Modal/DMModal';
import { Alert, Col, Form, Input, InputNumber, message, Row, Select } from 'antd';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import { getAppProxy, sendAsync, setAppProxy } from '@/utils/ElectronUtils';
import DmSwitch from '@/components/Switch';

type Props = {
  onClose: () => void;
};

/**
 * 客户端代理设置
 * @param props
 * @constructor
 */
const AppProxySettingsModal: React.FC<Props> = (props) => {
  const { onClose } = props;
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const initValues = useMemo(() => {
    const { mode = 'direct', proxyRules = '', useBackupEndpoint = false } = getAppProxy();
    return {
      type: ['direct', 'system'].includes(mode) ? mode : proxyRules.split('://')[0],
      host: proxyRules ? proxyRules.split('://')[1].split(':')[0] : undefined,
      port: proxyRules ? proxyRules.split('://')[1].split(':')[1] : undefined,
      useBackupEndpoint,
    };
  }, []);

  return (
    <DMModal
      visible
      title={I18N.t('网络类型设置')}
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      closable={false}
      bodyStyle={{ paddingBottom: 0, '-webkit-app-region': 'no-drag' }}
    >
      <Alert
        showIcon
        message={I18N.t('花漾的服务器位于广东深圳，请根据您当前的网络环境进行设置')}
        style={{ marginBottom: 16 }}
      />

      <DMFormItemContext.Provider value={{ disableLabelMuted: true, labelWidth: 100 }}>
        <Form
          form={form}
          requiredMark={false}
          initialValues={initValues}
          onFinish={(values) => {
            console.log(values);
            const proxyConfig: Record<string, any> = {
              useBackupEndpoint: values.useBackupEndpoint,
            };
            if (['direct', 'system'].includes(values.type)) {
              proxyConfig.mode = values.type;
            } else {
              proxyConfig.mode = 'fixed_servers';
              proxyConfig.proxyRules = `${values.type}://${values.host}:${values.port}`;
            }
            setLoading(true);
            sendAsync('set-app-proxy', proxyConfig)
              .then(() => {
                setAppProxy(proxyConfig);
                onClose();
              })
              .catch((e) => {
                message.error(e.message);
                console.error(e);
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          <DMFormItem name="type" label={I18N.t('网络类型')}>
            <Select>
              <Select.Option value="direct">{I18N.t('不使用代理')}</Select.Option>
              <Select.Option value="http">{I18N.t('使用HTTP代理')}</Select.Option>
              <Select.Option value="socks5">{I18N.t('使用SOCKS5代理')}</Select.Option>
              <Select.Option value="system">{I18N.t('使用操作系统代理')}</Select.Option>
            </Select>
          </DMFormItem>
          <DMFormItem noStyle shouldUpdate>
            {(f) => {
              const type = f.getFieldValue('type');
              if (type === 'direct' || type === 'system') {
                return null;
              }
              return (
                <>
                  <Row gutter={16}>
                    <Col span={16}>
                      <DMFormItem
                        name="host"
                        label={I18N.t('服务器地址')}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </DMFormItem>
                    </Col>
                    <Col span={8}>
                      <DMFormItem
                        name="port"
                        label={I18N.t('端口')}
                        labelWidth={60}
                        rules={[{ required: true }]}
                      >
                        <InputNumber style={{ width: '100%' }} />
                      </DMFormItem>
                    </Col>
                  </Row>
                </>
              );
            }}
          </DMFormItem>
          <DMFormItem
            name="useBackupEndpoint"
            label={I18N.t('启用备用线路')}
            valuePropName="checked"
          >
            <DmSwitch />
          </DMFormItem>
        </Form>
      </DMFormItemContext.Provider>
    </DMModal>
  );
};

export default AppProxySettingsModal;

export function useAppProxySettingsModal() {
  const modalCaller = useModalCaller(true);
  return useCallback(
    (props: Omit<Props, 'onClose'>) => {
      modalCaller({
        component: ({ close }) => <AppProxySettingsModal {...props} onClose={close} />,
      });
    },
    [modalCaller],
  );
}

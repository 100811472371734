import I18N from '@/i18n';
import type { SelectorProps } from '@/components/Common/Selector/index';
import Selector from '@/components/Common/Selector/index';
import { OptionPlaceholder } from '@/components/Common/Placeholder';
import React, { useState } from 'react';
import { Space } from 'antd';
import ColoursIcon from '@/components/Common/ColoursIcon';
import constants from '@/constants';
import usePlatforms from '@/hooks/usePlatforms';
import { isIconValid } from '@/utils/iconUtils';
import { useCtrlMode } from '@/hooks/interactions';

interface PlatformSelectProps extends SelectorProps {
  includeAll?: boolean;
  filter?: (item: API.PlatformVo) => boolean;
}

export const Icon = (props: { type: string }) => {
  const { type, ...others } = props;
  let className = type === 'Other' ? 'qitapingtai_24' : `${type}_24`;

  // 如果type以任意数字开头的话得加上icon_前缀
  if (type?.match(/^\d+/)) {
    className = `icon_${className}`;
  }
  if (!isIconValid(className)) {
    return <ColoursIcon className={'qitapingtai_24'} {...others} />;
  }
  return <ColoursIcon className={className} {...others} />;
};

/**
 * 分身平台选择器
 * @param props
 * @constructor
 */
const CategorySelector: React.FC<PlatformSelectProps> = (props) => {
  const { includeAll, filter, onChange, mode, ...others } = props;
  const platformList = usePlatforms(undefined, includeAll);
  const [open, setOpen] = useState<boolean>(false);
  const { dom, active } = useCtrlMode(null);
  const options = (
    filter
      ? platformList.filter((item) => {
          return filter(item);
        })
      : platformList
  ).map((item) => {
    const { type, category } = item;
    return {
      value: type,
      label: (
        <Space size={4}>
          <Icon size={14} type={type} />
          {constants.PlatformType[type] || type}
        </Space>
      ),
      category,
      ...item,
    };
  });
  return (
    <Selector
      showPlaceholderOption
      open={open}
      onDropdownVisibleChange={(open) => {
        setOpen(open);
      }}
      mode={mode}
      dropdownRender={
        mode === 'multiple'
          ? (menu) => {
              return (
                <div
                  onMouseLeave={(e) => {
                    // 如果当前没有按住control或者cmd,就收起
                    if (!active) {
                      setOpen(false);
                    }
                  }}
                >
                  {menu}
                  {dom}
                </div>
              );
            }
          : undefined
      }
      onChange={(value, option) => {
        if (!active && mode === 'multiple') {
          setOpen(false);
          onChange?.(value?.length ? [value.pop()] : [], option);
        } else {
          onChange?.(value, option);
        }
      }}
      maxTagPlaceholder={(omittedValues) => {
        const count = omittedValues.length;
        return `...${count}${I18N.t('个平台')}`;
      }}
      horizontal
      placeholder={<OptionPlaceholder type="platform" />}
      dropdownClassName={'platform-selector-overlay'}
      options={options}
      {...others}
    />
  );
};
export default CategorySelector;

import { Tooltip, Typography } from 'antd';
import IconFontIcon from '@/components/Common/IconFontIcon';
import HelpLink from '@/components/HelpLink';
import { useMemo } from 'react';

const HelpTooltip = (props: {
  title: any;
  link?: string;
  style?: React.CSSProperties;
  type?: 'Text' | 'Link';
}) => {
  const { title, link, style, type } = props;
  const Component = useMemo(() => {
    if (type === 'Link') {
      return Typography.Link;
    }
    return Typography.Text;
  }, [type]);
  return (
    <Tooltip
      overlayInnerStyle={{ textAlign: 'justify' }}
      title={
        <div>
          {title}
          {link && (
            <HelpLink
              style={{ float: 'right', color: 'inherit', textDecoration: 'underline' }}
              href={link}
            />
          )}
        </div>
      }
    >
      <Component
        className={'help-tooltip-trigger'}
        style={{ cursor: 'pointer', fontSize: 'inherit', ...style }}
      >
        <IconFontIcon size={'inherit'} iconName="bangzhu_24" />
      </Component>
    </Tooltip>
  );
};
export default HelpTooltip;

import type { DropDownProps } from 'antd';
import { Button, Dropdown, Menu, Space, Tooltip } from 'antd';
import IconFontIcon from '@/components/Common/IconFontIcon';
import type { FC } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import type { Order } from '@/components/Sort/SortTitle';
import SortTitle from '@/components/Sort/SortTitle';
import useCurrentTeam from '@/hooks/useCurrentTeam';
import useCurrentUser from '@/hooks/useCurrentUser';
import { useLocalStorageState } from '@umijs/hooks';
import I18N from '@/i18n/I18N';

type Item = {
  label: JSX.Element | string;
  key: string;
};

interface Props extends Omit<DropDownProps, 'overlay'> {
  list: Item[];
  order: Order;
  onChange: (order: Order) => void;
}

/**
 * 排序hook
 * @param options
 * @param stateKey 持久化key
 */
export function useOrder(options: Order, stateKey?: string) {
  const { team } = useCurrentTeam();
  const { user } = useCurrentUser();
  const storageKey = useMemo(
    () => `order-state-${stateKey}-${user?.id}-${team?.id}`,
    [stateKey, team?.id, user?.id],
  );
  const [order, changeOrder] = useLocalStorageState(storageKey, options);
  return {
    order,
    changeOrder,
  };
}
/**
 * 排序下拉框
 */
const SortDropdown: FC<Props> = (props) => {
  const { list, order, onChange, arrow, trigger, ...others } = props;
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
  }, []);
  const overlay = useMemo(() => {
    const menus: any[] = [];
    list.forEach((item) => {
      const { label, key } = item;
      menus.push(
        <Menu.Item
          key={key}
          onClick={() => {
            if (order.key === key) {
              onChange({
                key,
                ascend: !order.ascend,
              });
            } else {
              onChange({
                key,
                ascend: true,
              });
            }
          }}
        >
          <SortTitle orderKey={key} label={label} order={order} />
        </Menu.Item>,
      );
    });
    return (
      <Menu selectable selectedKeys={[order.key]}>
        {menus}
      </Menu>
    );
  }, [list, onChange, order]);
  return (
    <Dropdown trigger={['click']} overlayStyle={{ width: '126px' }} overlay={overlay} {...others}>
      <Tooltip title={I18N.t('对数据列表进行排序')} placement="topRight">
        <Button style={{ paddingLeft: 8, paddingRight: 8 }}>
          <Space size={4} align="center" style={{ color: '#666666' }}>
            <IconFontIcon iconName={'paixu-1_24'} />
            <span>{I18N.t('排序')}</span>
          </Space>
        </Button>
      </Tooltip>
    </Dropdown>
  );
};
export default SortDropdown;

// 客户端、web通用路由
import Functions from './../src/constants/Functions';

export const appRoutes = [
  {
    path: '/login',
    layout: false,
    name: '登录',
    locale: '登录',
    hideInMenu: true,
    component: './UserLogin/Login',
  },
  {
    path: '/register',
    layout: false,
    name: '注册',
    locale: '注册',
    hideInMenu: true,
    component: './UserLogin/Register',
  },
  {
    path: '/findPassword',
    layout: false,
    name: '找回密码',
    locale: '找回密码',
    hideInMenu: true,
    component: './UserLogin/FindPassword',
  },
  {
    path: '/invite/wechat/:inviteCode',
    layout: false,
    name: '邀请注册',
    locale: '邀请注册',
    hideInMenu: true,
    component: './UserLogin/Invite/ByQrcode',
  },
  {
    path: '/invite/:inviteCode',
    layout: false,
    name: '邀请注册',
    locale: '邀请注册',
    hideInMenu: true,
    component: './UserLogin/Invite',
  },
  {
    path: '/link/invite-join-team/:code',
    layout: false,
    name: '团队邀请',
    locale: '团队邀请',
    hideInMenu: true,
    component: './Link/InviteJoinTeam',
  },
  {
    path: '/selectTeam',
    layout: false,
    name: '选择团队',
    locale: '选择团队',
    hideInMenu: true,
    component: './UserLogin/SelectTeam',
  },
  {
    path: '/systemPref',
    layout: false,
    name: '偏好设置',
    locale: '偏好设置',
    hideInMenu: true,
    component: './SystemPref',
  },
  {
    path: '/aboutRuntime',
    layout: false,
    name: '关于',
    locale: '关于',
    hideInMenu: true,
    component: './AboutRuntime',
  },
  {
    path: '/modal',
    layout: false,
    name: '请确认',
    locale: '请确认',
    hideInMenu: true,
    component: './Modal',
  },
  {
    path: '/',
    hideInMenu: true,
    redirect: '/login',
  },

  // 这个必须放到最后
  {
    component: './404',
    hideInMenu: true,
  },
];

export const commonRoutes = [
  {
    name: '购买云主机',
    locale: '购买云主机',
    path: '/ipMarket',
    layout: false,
    component: './IpMarket',
    hideInMenu: true,
  },
  {
    path: '/result/scan/:verifyId',
    layout: false,
    name: '扫码结果页',
    locale: '扫码结果页',
    hideInMenu: true,
    component: './Result',
  },
  {
    path: '/result/oauth',
    layout: false,
    name: '授权结果',
    locale: '授权结果',
    hideInMenu: true,
    component: './OauthResult',
  },
  {
    path: '/myProfile',
    layout: false,
    name: '我获得的奖励',
    locale: '我获得的奖励',
    hideInMenu: true,
    component: './MyInviteCode',
  },
  ...appRoutes,
];

// 花漾灵动web页面路由
export const browserRoutes = [
  {
    path: '/',
    layout: false,
    hideInMenu: true,
    component: './browser/ShopLandPage',
  },
  {
    path: '/accessDeny',
    layout: false,
    hideInMenu: true,
    component: './browser/AccessDeny',
  },
  {
    path: '/creditAlert',
    layout: false,
    hideInMenu: true,
    component: './browser/CreditAlert',
  },
  {
    path: '/newTab',
    layout: false,
    hideInMenu: true,
    component: './browser/NewTabPage',
  },
];

const routes = [
  {
    name: '团队',
    locale: '团队',
    key: 'team',
    path: '/team/:teamId',
    routes: [
      {
        path: '/team/:teamId',
        component: './TeamIndex',
        hideInMenu: true,
      },
      {
        name: '文件预览',
        locale: '文件预览',
        path: '/team/:teamId/preview',
        layout: false,
        component: './FilePreview',
        hideInMenu: true,
      },
      {
        name: '购买云主机',
        locale: '购买云主机',
        wrappers: ['@/wrappers/team'],
        path: '/team/:teamId/ipMarket',
        layout: false,
        component: './IpMarket',
        hideInMenu: true,
      },
      {
        name: '首页',
        locale: '首页',
        path: '/team/:teamId/dashboard',
        key: 'dashboard',
        needSpecialRole: true,
        className: 'shouye_24',
        component: './Dashboard',
        wrappers: ['@/wrappers/team'],
      },
      {
        name: '分身',
        locale: '分身',
        path: '/team/:teamId/shopManage/:type?/:shopId?',
        key: 'shopManage',
        className: 'chrome_outline',
        component: './ShopManage',
        wrappers: ['@/wrappers/team'],
        routes: [
          {
            name: '分身详情',
            locale: '分身详情',
            path: '/team/:teamId/shopManage/:type/:shopId',
          },
        ],
      },
      {
        name: '手机',
        locale: '手机',
        path: '/team/:teamId/phoneManage/:resourceType?/:resourceId?',
        key: 'phoneManage',
        className: 'yidongwangluoIP_24',
        component: './Phone',
        wrappers: ['@/wrappers/team'],
      },
      {
        name: '手机',
        locale: '手机',
        path: '/team/:teamId/phonePopup/:phoneId',
        key: 'phonePopup',
        component: './PhonePopup',
        layout: false,
        hideInMenu: true,
        wrappers: ['@/wrappers/team'],
      },
      {
        name: 'IP地址',
        locale: 'IP地址',
        path: '/team/:teamId/ipManage/:type?/:ipId?',
        key: 'ipManage',
        className: 'IP_24',
        component: './IpManage',
        functionCode: Functions.IP_LIST,
        wrappers: ['@/wrappers/team'],
        routes: [
          {
            name: 'IP详情',
            locale: 'IP详情',
            path: '/team/:teamId/ipManage/:type/:ipId',
          },
        ],
      },
      {
        name: '流程编辑',
        locale: '流程编辑',
        path: '/team/:teamId/rpa/flow/:flowId',
        component: './Rpa/FlowDetail',
        functionCode: Functions.RPA_CREATE_DELETE,
        wrappers: ['@/wrappers/team'],
        layout: false,
        hideInMenu: true,
      },
      {
        path: '/team/:teamId/rpa/popup/task/:taskId',
        layout: false,
        name: '流程任务',
        locale: '流程任务',
        wrappers: ['@/wrappers/team'],
        hideInMenu: true,
        component: './Rpa/TaskPopup',
        extract: true,
      },
      {
        name: '自动化',
        locale: '自动化',
        path: '/team/:teamId/rpa/:type?/:taskId?',
        key: 'rpa',
        className: 'jiqiren_24',
        component: './Rpa',
        functionCode: Functions.RPA_LIST,
        wrappers: ['@/wrappers/team'],
        routes: [
          {
            name: '流程管理',
            locale: '流程管理',
            path: '/team/:teamId/rpa/flow',
          },
          {
            name: '流程管理',
            locale: '流程管理',
            path: '/team/:teamId/rpa/flowGroup/:groupId',
          },
          {
            name: '流程计划',
            locale: '流程计划',
            path: '/team/:teamId/rpa/schedule',
          },
          {
            name: '流程任务',
            locale: '流程任务',
            path: '/team/:teamId/rpa/task',
          },
          {
            name: '任务详情',
            locale: '任务详情',
            path: '/team/:teamId/rpa/task/:taskId',
          },
          {
            name: '内置到浏览器的流程',
            locale: '内置到浏览器的流程',
            path: '/team/:teamId/rpa/browserFlow',
          },
          {
            name: 'Open API',
            locale: 'Open API',
            path: '/team/:teamId/rpa/openapi',
          },
        ],
      },
      {
        name: '插件',
        locale: '插件',
        path: '/team/:teamId/extension/:type?/:id?',
        key: 'extension',
        className: 'chajian_24',
        component: './Extension',
        functionCode: Functions.EXTENSION_LIST,
        wrappers: ['@/wrappers/team'],
      },
      {
        path: '/team/:teamId/rpaTaskMonitor/:taskId',
        layout: false,
        name: '实时查看',
        locale: '实时查看',
        hideInMenu: true,
        wrappers: ['@/wrappers/team'],
        component: './Rpa/TaskDetail/Monitor',
      },

      {
        name: '费用',
        locale: '费用',
        key: 'costManage',
        className: 'feiyong_24',
        path: '/team/:teamId/costManage/:type?',
        // functionCode: Functions.EXPENSE_VIEW,
        component: './Cost',
        wrappers: ['@/wrappers/team'],
      },
      {
        name: '团队',
        locale: '团队',
        key: 'teamManage',
        className: 'tuandui_24',
        path: '/team/:teamId/teamManage/:action?',
        component: './TeamManage',
        functionCode: Functions.TEAM_MANAGE,
        wrappers: ['@/wrappers/team'],
        routes: [
          {
            name: '成员管理',
            locale: '成员管理',
            path: '/team/:teamId/teamManage/users/:departmentId?',
            component: './TeamManage/UserManage',
          },
          {
            name: '团队设置',
            locale: '团队设置',
            path: '/team/:teamId/teamManage/setting',
            component: './TeamManage/TeamSetting',
          },
          {
            name: '角色权限',
            locale: '角色权限',
            path: '/team/:teamId/teamManage/access',
            component: './TeamManage/AuthPane',
          },
          {
            name: '成员审核',
            locale: '成员审核',
            path: '/team/:teamId/teamManage/audit/:auditStatus?',
            component: './TeamManage/TeamAudit',
          },
          {
            name: '安全设置',
            locale: '安全设置',
            path: '/team/:teamId/teamManage/secure/:type?',
            component: './TeamManage/TeamSecure',
          },
          {
            name: '团队云盘',
            locale: '团队云盘',
            path: '/team/:teamId/teamManage/yunpan/:type?/:path?',
            component: './Yunpan',
          },
          {
            name: '操作日志',
            locale: '操作日志',
            path: '/team/:teamId/teamManage/logs/:logType?',
            className: 'rizhi_24',
            component: './Logs',
            // functionCode: [
            //   Functions.OPERATE_LOG_GET_SHOP,
            //   Functions.OPERATE_LOG_MANAGE_SHOP,
            //   Functions.OPERATE_LOG_GET_LOGIN,
            //   Functions.OPERATE_LOG_GET_TEAM_MANAGE,
            //   Functions.OPERATE_LOG_GET_IP,
            // ],
            routes: [
              {
                name: '分身访问日志',
                locale: '分身访问日志',
                path: '/team/:teamId/teamManage/logs/shopVisitLogs',
                component: './Logs/ShopVisitLogs',
              },
              {
                name: '分身管理日志',
                locale: '分身管理日志',
                path: '/team/:teamId/teamManage/logs/shopManageLogs',
                component: './Logs/ShopManageLogs',
              },
              {
                name: 'IP日志',
                locale: 'IP日志',
                path: '/team/:teamId/teamManage/logs/ipLogs',
                component: './Logs/IpLogs',
              },
              {
                name: 'IP流量日志',
                locale: 'IP流量日志',
                path: '/team/:teamId/teamManage/logs/ipTrafficLogs',
                component: './Logs/IpTrafficLogs',
              },
              {
                name: '团队管理日志',
                locale: '团队管理日志',
                path: '/team/:teamId/teamManage/logs/teamManageLogs',
                component: './Logs/TeamManageLogs',
              },
              {
                name: '成员登录日志',
                locale: '成员登录日志',
                path: '/team/:teamId/teamManage/logs/memberLoginLogs',
                component: './Logs/MemberLoginLogs',
              },
            ],
          },
          {
            hideInMenu: true,
            redirect: '/team/:teamId/teamManage/setting',
          },
        ],
      },
      {
        path: '/team/:teamId/record/shop/:shopId/session/:sessionId',
        layout: false,
        name: '录像',
        locale: '录像',
        hideInMenu: true,
        component: './Record',
      },
      {
        name: '实时监管',
        locale: '实时监管',
        path: '/team/:teamId/watch/shop/:shopId/session/:sessionId',
        component: './Watcher',
        functionCode: Functions.SHOP_AUTHORIZE,
        wrappers: ['@/wrappers/team'],
        layout: false,
        hideInMenu: true,
      },
      {
        path: '/team/:teamId/shopLandPage/:shopId',
        layout: false,
        hideInMenu: true,
        component: './browser/ShopLandPage',
      },
      {
        path: '/team/:teamId/newTab/:shopId',
        layout: false,
        hideInMenu: true,
        component: './browser/NewTabPage',
      },
      {
        path: '/team/:teamId/accessDeny/:shopId',
        layout: false,
        hideInMenu: true,
        component: './browser/AccessDeny',
      },
      {
        path: '/team/:teamId/alipay/:orderId',
        layout: false,
        hideInMenu: true,
        component: './Cost/AlipayPay',
      },
      {
        name: '轨迹',
        locale: '轨迹',
        path: '/team/:teamId/tracks/:resourceType/:resourceId',
        layout: false,
        hideInMenu: true,
        component: './Tracks',
        functionCode: [Functions.SHOP_AUTHORIZE, Functions.IP_CONFIG],
      },
      {
        path: '/team/:teamId/runTask/:flowId',
        layout: false,
        name: '执行流程',
        locale: '执行流程',
        wrappers: ['@/wrappers/team'],
        hideInMenu: true,
        component: './RunFlow',
      },
    ],
  },
  {
    path: '/fingerprint/token/:token',
    layout: false,
    name: '浏览器指纹采集器',
    locale: '浏览器指纹采集器',
    hideInMenu: true,
    component: './Fingerprint',
  },
  {
    path: '/wx/loginStatus/:sessionId',
    layout: false,
    name: '微信登录',
    locale: '微信登录',
    hideInMenu: true,
    component: './Wechat/LoginStatus',
  },
  {
    path: '/wx/message/:messageId',
    layout: false,
    name: '花漾RPA消息',
    locale: '花漾RPA消息',
    hideInMenu: true,
    component: './Wechat/Message',
  },
  {
    path: '/rpaCaptcha/token/:token',
    layout: false,
    name: '输入验证码',
    locale: '输入验证码',
    hideInMenu: true,
    component: './RpaCaptcha',
  },
  {
    path: '/rpaSelectorPicker/:flowId',
    layout: false,
    name: '元素捕获器',
    locale: '元素捕获器',
    hideInMenu: true,
    component: './RpaSelectorPicker',
  },
  {
    path: '/shortcut/:token',
    layout: false,
    name: '分身',
    locale: '分身',
    hideInMenu: true,
    component: './Shortcut',
  },
  {
    path: '/windowSyncToolbox',
    layout: false,
    name: '窗口管理器',
    locale: '窗口管理器',
    hideInMenu: true,
    component: './WindowSyncToolbox',
  },
  ...commonRoutes,
];

export default routes;

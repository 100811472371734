import I18N from '@/i18n';
import ProTable from '@ant-design/pro-table';
import pMinDelay from 'p-min-delay';
import type { ButtonProps } from 'antd';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Row,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import Segmented from 'rc-segmented';
import { useModel } from 'umi';
import formStyles from '@/style/form.less';
import 'rc-segmented/assets/index.css';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { scrollProTableOptionFn } from '@/mixins/table';
import styles from './index.less';
import buttonStyles from '@/style/button.less';
import IconFontIcon from '@/components/Common/IconFontIcon';
import DMConfirm from '@/components/Common/DMConfirm';
import { getTeamIdFromUrl, isWinPlatform } from '@/utils/utils';
import DMModal from '@/components/Common/Modal/DMModal';
import { GhostModalCaller } from '@/mixins/modal';
import copy from 'copy-to-clipboard';
import type { TextAreaRef } from 'antd/es/input/TextArea';
import Tips from '@/components/Common/Tips';
import { emitEvent, sendAsync } from '@/utils/ElectronUtils';
import Placeholder from '@/components/Common/Placeholder';
import { useLocalStorageState } from '@umijs/hooks';
import PlatformCateIcon from '@/components/Common/ShopCategorySelector/PlatformCateIcon';
import type { Session } from '@/models/windowSync';
import useWindowSync from '@/hooks/useWindowSync';
import HelpLink from '@/components/HelpLink';
import CopyableText from '@/components/Common/CopyableText';
import SortDropdown from '@/components/Sort/SortDropdown';
import Slot from '@/components/Slot';
import { HEADER_RIGHT_SLOT_ID } from '@/pages/ShopManage/utils';
import GridLayoutForm from '@/pages/ShopManage/WindowSync/components/GridLayoutForm';
import DiagonalLayoutForm from '@/pages/ShopManage/WindowSync/components/DiagonalLayoutForm';
import HelpTooltip from '@/components/HelpTooltip';
import moment from 'moment';
import { StyledOverflow } from '@/components/Common/MoreDropdown';
import { useUserSettingsModal } from '@/components/UserCenter/components/UserSettingsModal';
import OpeningShopListTable from '@/pages/ShopManage/WindowSync/components/OpeningShopListTable';

export type SequenceOption = {
  template: string;
  sequence: string;
  step: number;
};

export function generateTemplateString(option: SequenceOption, num: number) {
  const { template, sequence, step } = option;
  const stringList: string[] = [];
  function randomString(length = 1, seed = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz') {
    let result = '';
    const characters = seed;
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  function randomChineseString(length = 1) {
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomUnicode = Math.floor(Math.random() * (0x9fa5 - 0x4e00 + 1)) + 0x4e00;
      result += String.fromCharCode(randomUnicode);
    }
    return result;
  }

  function randomInt(length = 1, min = 0, max = 9) {
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      result += String(randomNumber);
    }
    return result;
  }

  const regex = /\{(char|CHAR|Char|汉|num|sequence|symbol)(\d+)?\}/g;
  let index = 0;
  while (index < num) {
    const _str = template.replace(regex, (match: string, type: string, length = '1') => {
      if (type === 'char') {
        return randomString(Number(length), 'abcdefghijklmnopqrstuvwxyz');
      }
      if (type === 'CHAR') {
        return randomString(Number(length), 'ABCDEFGHIJKLMNOPQRSTUVWXYZ');
      }
      if (type === 'Char') {
        return randomString(Number(length));
      }
      if (type === I18N.t('汉')) {
        return randomChineseString(Number(length));
      }
      if (type === 'num') {
        return randomInt(Number(length));
      }
      if (type === 'sequence') {
        return sequence + index * step;
      }
      if (type === 'symbol') {
        return randomString(Number(length), '!@#$%^&*');
      }
      return match;
    });
    stringList.push(_str);
    index++;
  }

  return stringList;
}

const InputLogModal = () => {
  const [visible, setVisible] = useState(true);
  const [history, setHistory] = useState<string>('');
  const inputRef = useRef<TextAreaRef>(null);

  useEffect(() => {
    sendAsync('get-send-keys-logs').then((data) => {
      const lastLog = data[data.length - 1];
      if (lastLog) {
        setHistory(
          lastLog.records
            .map((r: any) => {
              return `${r.shopName},${r.logs.join(',')}`;
            })
            .join('\n'),
        );
      }
    });
  }, []);

  return (
    <DMModal
      title={I18N.t('日志')}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => {
        inputRef.current?.resizableTextArea?.textArea.select();
        copy(history);
        message.success(I18N.t('已复制至剪切板'));
      }}
      okText={I18N.t('复制')}
      extraButtons={[
        <Button
          key="download"
          type="link"
          onClick={() => {
            emitEvent('download-content', {
              content: history,
              filename: `${I18N.t('文本输入记录.csv')}`,
            });
          }}
        >
          {I18N.t('下载CSV文件')}
        </Button>,
      ]}
    >
      <Alert
        showIcon
        style={{ marginBottom: 16 }}
        message={I18N.t(
          '本次群控中输入的所有文本的记录，以英文逗号分隔，可下载成CSV文件后用Excel打开',
        )}
      />

      <Input.TextArea
        ref={inputRef}
        value={history}
        readOnly
        style={{ height: 300, resize: 'none', overflow: 'auto' }}
      />
    </DMModal>
  );
};
export const FastInputModal = (props: { tiny?: boolean; onSubmit: (values: string[]) => void }) => {
  const { tiny = false, onSubmit } = props;
  const [visible, setVisible] = useState(true);
  const inputRef = useRef<TextAreaRef>(null);
  const [log, setLog] = useState<string>('');

  return (
    <DMModal
      title={tiny ? false : I18N.t('文本导入')}
      closable={!tiny}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => {
        onSubmit(log.split('\n'));
        setVisible(false);
      }}
    >
      {!tiny && (
        <Alert
          showIcon
          style={{ marginBottom: 16 }}
          message={I18N.t('将输入内容以换行符作为分割，依次填入到分身窗口中')}
        />
      )}

      <Input.TextArea
        ref={inputRef}
        value={log}
        autoFocus
        onChange={(e) => {
          setLog(e.target.value);
        }}
        onPressEnter={(e) => {
          if (!e.ctrlKey && !e.shiftKey && !e.altKey) {
            e.preventDefault();
            onSubmit(log.split('\n'));
            setVisible(false);
          }
        }}
        placeholder={I18N.t('以换行符（Shift + Enter）作为分割')}
        style={{ height: tiny ? 200 : 300, resize: 'none', overflow: 'auto' }}
      />
    </DMModal>
  );
};
export const InputGenerateModal = (props: {
  sessions: Session[];
  tiny?: boolean;
  onSubmit: (values: string[]) => void;
}) => {
  const { sessions, tiny = false, onSubmit } = props;
  const [visible, setVisible] = useState(true);
  const [form] = Form.useForm();
  const inputRef = useRef<Input>(null);

  useEffect(() => {
    form.setFieldsValue({
      template: localStorage.getItem('browser.sync.generate.text') ?? '',
    });
  }, [form]);

  const generateString = useCallback(() => {
    const { template, sequence, step } = form.getFieldsValue();
    let stringList = [];
    function randomString(
      length = 1,
      seed = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    ) {
      let result = '';
      const characters = seed;
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    }

    function randomChineseString(length = 1) {
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomUnicode = Math.floor(Math.random() * (0x9fa5 - 0x4e00 + 1)) + 0x4e00;
        result += String.fromCharCode(randomUnicode);
      }
      return result;
    }

    function randomInt(length = 1, min = 0, max = 9) {
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        result += String(randomNumber);
      }
      return result;
    }

    const regex = /\{(char|CHAR|Char|汉|num|sequence|symbol)(\d+)?\}/g;
    stringList = sessions.map((item, index) => {
      const replacedString = template.replace(
        regex,
        (match: string, type: string, length = '1') => {
          if (type === 'char') {
            return randomString(Number(length), 'abcdefghijklmnopqrstuvwxyz');
          }
          if (type === 'CHAR') {
            return randomString(Number(length), 'ABCDEFGHIJKLMNOPQRSTUVWXYZ');
          }
          if (type === 'Char') {
            return randomString(Number(length));
          }
          if (type === I18N.t('汉')) {
            return randomChineseString(Number(length));
          }
          if (type === 'num') {
            return randomInt(Number(length));
          }
          if (type === 'sequence') {
            return sequence + index * step;
          }
          if (type === 'symbol') {
            return randomString(Number(length), '!@#$%^&*');
          }
          return match;
        },
      );
      return replacedString;
    });
    return stringList;
  }, [form, sessions]);

  return (
    <DMModal
      title={tiny ? false : I18N.t('文本生成')}
      closable={!tiny}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={() => {
        form.submit();
      }}
    >
      {!tiny && (
        <Alert showIcon style={{ marginBottom: 16 }} message={I18N.t('通过表达式生成随机文本')} />
      )}
      <Form
        form={form}
        onFinish={() => {
          onSubmit(generateString());
          setVisible(false);
        }}
      >
        <Form.Item
          name={'template'}
          rules={[
            {
              required: true,
              whitespace: true,
              message: I18N.t('请输入表达式'),
            },
          ]}
        >
          <Input
            ref={inputRef}
            className={styles.textGenerateInput}
            autoFocus
            spellCheck={false}
            placeholder={I18N.t('请输入表达式')}
            onPressEnter={form.submit}
            onChange={(e) => {
              localStorage.setItem('browser.sync.generate.text', e.target.value ?? '');
            }}
          />
        </Form.Item>
        <Row wrap={false}>
          <Col flex={1}>
            <Form.Item
              label={I18N.t('sequence初始值')}
              name={'sequence'}
              initialValue={1}
              labelCol={
                tiny ? { style: { flex: '0 0 auto', marginRight: 4, paddingBottom: 0 } } : undefined
              }
              wrapperCol={tiny ? { style: { flex: '0 0 auto' } } : undefined}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              label={I18N.t('步长')}
              name={'step'}
              initialValue={1}
              labelCol={
                tiny ? { style: { flex: '0 0 auto', marginRight: 4, paddingBottom: 0 } } : undefined
              }
              wrapperCol={tiny ? { style: { flex: '0 0 auto' } } : undefined}
            >
              <InputNumber min={1} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {tiny ? (
        <div>
          {I18N.t('示例：')}
          {`${I18N.t('{char5}{CHAR6}{Char7}{汉6}{num}{sequence}{symbol}')}`}
          <a>
            <HelpTooltip
              title={
                <>
                  <div>
                    {`{char5}`}
                    {I18N.t('：生成5个随机的小写英文字符')}
                  </div>
                  <div>
                    {`{CHAR6}`}
                    {I18N.t('：生成6个随机的大写英文字符')}
                  </div>
                  <div>
                    {`{Char7}`}
                    {I18N.t('：生成7个随机的大小写英文字符')}
                  </div>
                  <div>
                    {`${I18N.t('{汉6}')}`}
                    {I18N.t('：生成6个随机的中文汉字')}
                  </div>
                  <div>
                    {`{num}`}
                    {I18N.t('：0-9随机的一个数字')}
                  </div>
                  <div>
                    {`{sequence}`}
                    {I18N.t('：从指定数字开始的顺序数字，步长值可设置')}
                  </div>
                  <div>
                    {`{symbol}`}
                    {I18N.t('：符号!@#$%^&*中的任意一个')}
                  </div>
                </>
              }
            />
          </a>
        </div>
      ) : (
        <Tips
          title={I18N.t('表达式使用说明')}
          content={[
            <span>
              <Typography.Text>{`{char5}`}</Typography.Text>
              {I18N.t('：生成5个随机的小写英文字符')}
              <br />
              <Typography.Text>{`{CHAR6}`}</Typography.Text>
              {I18N.t('：生成6个随机的大写英文字符')}
              <br />
              <Typography.Text>{`{Char7}`}</Typography.Text>
              {I18N.t('：生成7个随机的大小写英文字符')}
              <br />
              <Typography.Text>{`${I18N.t('{汉6}')}`}</Typography.Text>
              {I18N.t('：生成6个随机的中文汉字')}
              <br />
              <Typography.Text>{`{num}`}</Typography.Text>
              {I18N.t('：0-9随机的一个数字')}
              <br />
              <Typography.Text>{`{sequence}`}</Typography.Text>
              {I18N.t('：从指定数字开始的顺序数字，步长值可设置')}

              <br />
              <Typography.Text>{`{symbol}`}</Typography.Text>
              {I18N.t('：符号!@#$%^&*中的任意一个')}
            </span>,
            I18N.t('常用示例'),

            <span>
              {I18N.t('注册账号：')}

              <CopyableText
                showIcon
                style={{ color: '#333' }}
                onCopy={(text) => {
                  const newText = (form.getFieldValue('template') ?? '') + (text ?? '');
                  form.setFieldsValue({
                    template: newText,
                  });
                  inputRef.current?.focus();
                  localStorage.setItem('browser.sync.generate.text', newText);
                }}
              >
                {`{Char12}{num}{num}{num}`}
              </CopyableText>
            </span>,
            <span>
              {I18N.t('高强度密码：')}

              <CopyableText
                showIcon
                style={{ color: '#333' }}
                onCopy={(text) => {
                  const newText = (form.getFieldValue('template') ?? '') + (text ?? '');
                  form.setFieldsValue({
                    template: newText,
                  });
                  inputRef.current?.focus();
                  localStorage.setItem('browser.sync.generate.text', newText);
                }}
              >
                {`{Char7}{num}{symbol}{num}`}
              </CopyableText>
            </span>,
          ]}
        />
      )}
    </DMModal>
  );
};

export const MainWindowSelectModal = (props: {
  onSelect: (
    item: Session,
    options: { renderMasterMark: boolean; syncTabs: boolean; toolboxWindow: boolean },
  ) => void;
  dataSource: Session[];
}) => {
  const { onSelect, dataSource } = props;
  const [visible, setVisible] = useState(true);
  const [selectedRows, setSelectedRows] = useState<Session[]>([dataSource[0]]);
  const [renderMasterMark, setRenderMasterMark] = useState(
    (localStorage.getItem('gc-opt-master-mark') || 'true') === 'true',
  );
  const [syncTabs, setSyncTabs] = useState(
    (localStorage.getItem('gc-opt-sync-tabs') || 'false') === 'true',
  );
  const [toolboxWindow, setToolboxWindow] = useState(
    (localStorage.getItem('gc-opt-toolbox-window') || 'true') === 'true',
  );
  const showUserSettingsModal = useUserSettingsModal();

  const isInToolboxWindow = useMemo(
    () => window.location.pathname.includes('windowSyncToolbox'),
    [],
  );

  return (
    <DMModal
      title={I18N.t('选择主控窗口')}
      visible={visible}
      onOk={() => {
        localStorage.setItem('gc-opt-master-mark', String(renderMasterMark));
        localStorage.setItem('gc-opt-sync-tabs', String(syncTabs));
        localStorage.setItem('gc-opt-toolbox-window', String(toolboxWindow));
        onSelect(selectedRows[0], { renderMasterMark, syncTabs, toolboxWindow });
        setVisible(false);
      }}
      okButtonProps={{
        disabled: !selectedRows.length,
      }}
      onCancel={() => {
        setVisible(false);
      }}
    >
      {!isInToolboxWindow && (
        <Alert
          showIcon
          message={
            <span>
              {I18N.t(
                '请选择一个浏览器分身窗口作为主控窗口，对主控窗口的所有操作会同步到其它窗口中',
              )}
              <HelpLink href={'/help/account/gc'} style={{ marginLeft: 20 }} />
            </span>
          }
        />
      )}
      <div
        style={{
          height: isInToolboxWindow ? 160 : 300,
          overflow: 'hidden',
          marginTop: isInToolboxWindow ? 0 : 16,
        }}
      >
        <ProTable<Session>
          dataSource={dataSource}
          size={'small'}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selectedRows?.map((item) => item.shop.id!),
            onChange(ks, rs) {
              setSelectedRows(rs);
            },
          }}
          columns={[
            {
              title: I18N.t('分身名称'),
              dataIndex: ['shop', 'name'],
              render: (text, record) => {
                return (
                  <Row
                    wrap={false}
                    gutter={8}
                    onClick={() => {
                      setSelectedRows([record]);
                    }}
                  >
                    <Col flex="0 0 auto">
                      <PlatformCateIcon platformName={record.shop.platform?.name ?? 'Other'} />
                    </Col>
                    <Col flex="1" style={{ overflow: 'hidden' }}>
                      <Typography.Text ellipsis>{text}</Typography.Text>
                    </Col>
                  </Row>
                );
              },
            },
            {
              title: I18N.t('状态'),
              key: 'status',
              width: 80,
              render(text, record) {
                const isRPA = !!record.rpaFlowId;
                if (isRPA) {
                  return 'RPA';
                }
                return <Placeholder />;
              },
            },
          ]}
          onRow={(record) => ({
            style: {
              cursor: 'pointer',
            },
            onClick: () => {
              setSelectedRows([record]);
            },
          })}
          {...scrollProTableOptionFn({
            pagination: false,
            rowKey: (item) => {
              return item.shop.id;
            },
          })}
        />
      </div>
      <div style={{ marginTop: 8 }}>
        <Checkbox
          checked={renderMasterMark}
          onChange={(e) => {
            setRenderMasterMark(e.target.checked);
          }}
        >
          {I18N.t('给主控窗口加上边框')}
        </Checkbox>
        {!isInToolboxWindow && (
          <a
            onClick={() => {
              showUserSettingsModal({
                initMenu: 'windowGC',
              });
            }}
            style={{ marginLeft: 50 }}
          >
            {I18N.t('高级设置')}
          </a>
        )}
      </div>
      <div style={{ marginTop: 8 }}>
        <Checkbox
          checked={syncTabs}
          onChange={(e) => {
            setSyncTabs(e.target.checked);
          }}
        >
          {I18N.t('以主控窗口为标准，统一所有窗口的标签')}
        </Checkbox>
      </div>
      {!isInToolboxWindow && (
        <div style={{ marginTop: 8 }}>
          <Checkbox
            checked={toolboxWindow}
            onChange={(e) => {
              setToolboxWindow(e.target.checked);
            }}
          >
            {I18N.t('显示窗口管理器')}
          </Checkbox>
        </div>
      )}
    </DMModal>
  );
};

const WindowAlign = forwardRef((props: { sessions: Session[]; controlled: boolean }, ref) => {
  const { sessions, controlled } = props;
  const [layout, setLayout] = useLocalStorageState<'grid' | 'diagonal'>(
    'browser.sync.align.layout',
    'grid',
  );
  const [activeTab, setActiveTab] = useState('window');
  const [sessionTableHeight, setSessionTableHeight] = useState(0);
  const [sendKeysLogs, setSendKeysLogs] = useState<any[]>([]);
  const [gridForm] = Form.useForm();
  const [diagonalForm] = Form.useForm();
  const [textForm] = Form.useForm();
  const [text, setText] = useState<string>('');
  const sameTextInputRef = useRef<Input>(null);
  const sameTextSubmitRef = useRef<any>(null);
  const diffTextSubmitRef = useRef<any>(null);
  const { setDisplays, master } = useModel('windowSync');
  const { getWindowPositionOptions, startSync } = useWindowSync();

  useImperativeHandle(
    ref,
    () => ({
      changeLayout: (v: 'grid' | 'diagonal') => {
        setLayout(v);
      },
    }),
    [],
  );

  useEffect(() => {
    sendAsync('get-screen-info').then((data) => {
      setDisplays(data.allDisplays);
      gridForm.setFieldsValue({ displayId: data.allDisplays[0].id });
      diagonalForm.setFieldsValue({ displayId: data.allDisplays[0].id });
    });
    try {
      gridForm.setFieldsValue(JSON.parse(localStorage.getItem('browser.sync.align.grid') ?? '{}'));
      diagonalForm.setFieldsValue(
        JSON.parse(localStorage.getItem('browser.sync.align.diagonal') ?? '{}'),
      );
    } catch (e) {
      console.log(e);
    }
  }, [diagonalForm, gridForm, setDisplays]);

  useEffect(() => {
    if (activeTab && sameTextInputRef.current) {
      sameTextInputRef.current.focus();
    }
  }, [activeTab]);

  useEffect(() => {
    const container = document.querySelector('#window-sync-wrap');
    if (!container) return;
    // 创建 ResizeObserver 实例
    const observer = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        const { height } = entry.contentRect;
        setSessionTableHeight(height - 273);
      }
    });
    observer.observe(container);
  }, []);

  useEffect(() => {
    if (isWinPlatform()) {
      sendAsync('get-send-keys-logs').then((data) => {
        setSendKeysLogs(data);
      });
    }
  }, [controlled]);

  const saveSettings = useCallback(async () => {
    let hasError = false;
    try {
      if (layout === 'grid') {
        await gridForm.validateFields();
      } else {
        await diagonalForm.validateFields();
      }
    } catch (e: any) {
      hasError = e.errorFields.length > 0;
    }
    if (!hasError) {
      // 窗口排列
      if (layout === 'grid') {
        // 宫格布局
        localStorage.setItem(
          'browser.sync.align.grid',
          JSON.stringify(gridForm.getFieldsValue(true)),
        );
      } else {
        // 对角线布局
        localStorage.setItem(
          'browser.sync.align.diagonal',
          JSON.stringify(diagonalForm.getFieldsValue(true)),
        );
      }
    }
    return hasError;
  }, [diagonalForm, gridForm, layout]);

  const windowAlign = useCallback(async () => {
    const hasError = await saveSettings();
    if (hasError) {
      return;
    }
    if (!sessions?.length) {
      DMConfirm({
        title: I18N.t('请选择您要排列的窗口'),
        type: 'info',
      });
      return;
    }
    const options = getWindowPositionOptions(layout, sessions);
    await sendAsync('browser.sync.align', options);
  }, [getWindowPositionOptions, layout, saveSettings, sessions]);

  const content = useMemo(() => {
    return (
      <div style={{ paddingTop: 24 }}>
        <GridLayoutForm
          form={gridForm}
          orderVisible
          hidden={layout !== 'grid'}
          onValuesChange={saveSettings}
        />

        <DiagonalLayoutForm
          form={diagonalForm}
          orderVisible
          hidden={layout !== 'diagonal'}
          onValuesChange={saveSettings}
        />

        <Button type={'primary'} onClick={windowAlign} block>
          {I18N.t('开始排列')}
        </Button>
      </div>
    );
  }, [gridForm, layout, saveSettings, diagonalForm, windowAlign]);
  return (
    <Tabs activeKey={activeTab} onChange={setActiveTab} centered>
      <Tabs.TabPane tab={I18N.t('窗口布局')} key={'window'}>
        <Segmented
          options={[
            { label: I18N.t('宫格布局'), value: 'grid' },
            { label: I18N.t('对角线布局'), value: 'diagonal' },
          ]}
          value={layout}
          onChange={setLayout}
        />

        {content}
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={
          <Tooltip title={!isWinPlatform() ? I18N.t('仅支持Windows平台') : null}>
            {I18N.t('群控输入')}
          </Tooltip>
        }
        disabled={!isWinPlatform()}
        key={'text'}
      >
        {controlled ? (
          <>
            <div>{I18N.t('相同文本输入：')}</div>
            <Row gutter={[8, 8]} style={{ marginBottom: 24, marginTop: 12 }}>
              <Col flex={1}>
                <Input
                  ref={sameTextInputRef}
                  autoFocus
                  value={text}
                  onPressEnter={(e) => {
                    e.preventDefault();
                    sameTextSubmitRef.current?.click();
                  }}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </Col>
              <Col>
                <Button
                  ref={sameTextSubmitRef}
                  disabled={sessions?.length === 0}
                  type={'primary'}
                  onClick={() => {
                    const sessionsData = {};
                    sessions.forEach((s) => {
                      sessionsData[s.sessionId] = text;
                    });
                    sendAsync('win/sendKeys', {
                      sessions: sessionsData,
                    });
                  }}
                >
                  {I18N.t('输入')}
                </Button>
              </Col>
            </Row>
            <div style={{ marginBottom: 12 }}>{I18N.t('差异化文本输入：')}</div>
            <Form
              form={textForm}
              onFinish={() => {
                const sessionsData = textForm.getFieldsValue();
                sendAsync('win/sendKeys', {
                  sessions: sessionsData,
                });
              }}
              style={{ overflow: 'hidden' }}
            >
              <ProTable<Session>
                size={'small'}
                dataSource={sessions}
                {...scrollProTableOptionFn({
                  rowKey: (item) => {
                    return item.shop.id;
                  },
                  scroll: {
                    y: sessionTableHeight || undefined,
                  },
                })}
                tableLayout={'fixed'}
                columns={[
                  {
                    width: 1,
                    dataIndex: ['shop', 'name'],
                    title: I18N.t('分身名称'),
                    ellipsis: true,
                    render: (_text, record) => {
                      return (
                        <Row wrap={false} gutter={8}>
                          <Col flex="0 0 auto">
                            <PlatformCateIcon
                              platformName={record.shop.platform?.name ?? 'Other'}
                            />
                          </Col>
                          <Col flex="1">
                            <Typography.Text
                              ellipsis
                              title={record.shop.name}
                              style={{ maxWidth: '100%' }}
                            >
                              {record.shop.name ?? '--'}
                              {record.sessionId === master ? '(*)' : ''}
                            </Typography.Text>
                          </Col>
                        </Row>
                      );
                    },
                  },
                  {
                    width: 2,
                    dataIndex: 'content',
                    title: (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {I18N.t('文本内容')}

                        <Space size={'large'}>
                          <Typography.Link
                            disabled={sessions?.length === 0}
                            onClick={() => {
                              GhostModalCaller(
                                <InputGenerateModal
                                  sessions={sessions}
                                  onSubmit={(values) => {
                                    const valuesMap = {};
                                    sessions.forEach((item, index) => {
                                      const { sessionId } = item;
                                      valuesMap[sessionId] = values[index];
                                    });
                                    textForm.setFieldsValue(valuesMap);
                                  }}
                                />,
                              );
                            }}
                          >
                            {I18N.t('文本生成')}
                          </Typography.Link>
                          <Typography.Link
                            disabled={sessions?.length === 0}
                            onClick={() => {
                              GhostModalCaller(
                                <FastInputModal
                                  onSubmit={(values) => {
                                    const valuesMap = {};
                                    sessions.forEach((item, index) => {
                                      const { sessionId } = item;
                                      valuesMap[sessionId] = values[index];
                                    });
                                    textForm.setFieldsValue(valuesMap);
                                  }}
                                />,
                              );
                            }}
                          >
                            {I18N.t('文本导入')}
                          </Typography.Link>
                        </Space>
                      </div>
                    ),

                    render(_dom, record) {
                      const { sessionId } = record;
                      return (
                        <Form.Item name={sessionId} initialValue={''} noStyle>
                          <Input
                            onPressEnter={(e) => {
                              e.preventDefault();
                              diffTextSubmitRef.current?.click();
                            }}
                          />
                        </Form.Item>
                      );
                    },
                  },
                ]}
              />

              <div
                style={{
                  paddingTop: 8,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 8,
                  alignItems: 'center',
                }}
              >
                <Tooltip
                  title={I18N.t('为方便您记录所有的输入内容，凡是输入的历史记录都会为您保存下来')}
                >
                  <Typography.Link
                    style={{ paddingRight: 8 }}
                    onClick={() => {
                      GhostModalCaller(<InputLogModal />);
                    }}
                  >
                    {I18N.t('查看历史记录')}
                  </Typography.Link>
                </Tooltip>
                <Button
                  type={'primary'}
                  className={buttonStyles.successBtn}
                  disabled={sessions?.length === 0}
                  onClick={() => {
                    // 显示文本输入悬浮窗口
                    sendAsync('browser.sync.open.toolbox', { teamId: getTeamIdFromUrl() });
                  }}
                >
                  {I18N.t('文本悬浮窗口')}
                </Button>
                <Button
                  ref={diffTextSubmitRef}
                  type={'primary'}
                  disabled={sessions?.length === 0}
                  onClick={textForm.submit}
                >
                  {I18N.t('输入')}
                </Button>
                <Button
                  disabled={sessions?.length === 0}
                  onClick={() => {
                    textForm.resetFields();
                  }}
                >
                  {I18N.t('清空')}
                </Button>
              </div>
            </Form>
          </>
        ) : (
          <div>
            <Row align="top" justify="center" gutter={8} style={{ marginTop: 100, color: '#999' }}>
              <Col>
                <IconFontIcon iconName={'bendirengongganyu_24'} size={50} color="#ccc" />
              </Col>
              <Col>
                <div>{I18N.t('未开启群控')}</div>
                <div style={{ marginTop: 8, fontSize: 12 }}>
                  {I18N.t('只有开启群控后才能够进行多窗口文本输入')}
                </div>
                <div style={{ marginTop: 8 }}>
                  <Tooltip
                    title={sessions?.length === 0 ? I18N.t('请至少选择一个分身') : null}
                    placement={'topLeft'}
                  >
                    <Button
                      type="primary"
                      disabled={sessions?.length === 0}
                      style={{ width: 110 }}
                      onClick={() => {
                        startSync(() => {
                          setLayout('grid');
                        });
                      }}
                    >
                      {I18N.t('开启群控')}
                    </Button>
                  </Tooltip>
                </div>
              </Col>
            </Row>
            <Typography.Paragraph type="secondary" style={{ marginTop: 100 }}>
              {I18N.t('本次打开花漾客户端后的群控历史：')}
            </Typography.Paragraph>
            <Table
              size="small"
              columns={[
                {
                  title: I18N.t('开始时间'),
                  dataIndex: 'startTime',
                  width: 120,
                  render: (dom, record) => {
                    return moment(record.startTime).format('MM-DD HH:mm');
                  },
                },
                {
                  title: I18N.t('结束时间'),
                  dataIndex: 'endTime',
                  width: 120,
                  render: (dom, record) => {
                    return moment(record.endTime).format('MM-DD HH:mm');
                  },
                },
                {
                  title: I18N.t('文本输入记录'),
                  key: 'opt',
                  render: (dom, record) => {
                    return (
                      <a
                        onClick={() => {
                          const fileContent = record.records
                            .map((r: any) => {
                              return `${r.shopName},${r.logs.join(',')}`;
                            })
                            .join('\n');
                          emitEvent('download-content', {
                            content: fileContent,
                            filename: `${I18N.t('文本输入记录_')}${moment(record.startTime).format(
                              'YYYYMMDDHHmmss',
                            )}.csv`,
                          });
                        }}
                      >
                        {I18N.t('下载CSV文件')}
                      </a>
                    );
                  },
                },
              ]}
              dataSource={sendKeysLogs}
              pagination={false}
              scroll={{ y: 250 }}
            />
          </div>
        )}
      </Tabs.TabPane>
    </Tabs>
  );
});

const WindowSync = () => {
  const [closingRowIds, setClosingRowIds] = useState<number[]>([]);
  const { order, changeOrder, sessionsSorted, controlled, selectedRows } = useModel('windowSync');
  const { fetchGcDetail, beforeOpen, startSync } = useWindowSync();
  const windowAlignRef = useRef<any>(null);

  return (
    <div id="window-sync-wrap" className={styles.windowSyncWrap}>
      <Slot.Emit id={HEADER_RIGHT_SLOT_ID}>
        <Form className={formStyles.searchFormInline} layout={'inline'}>
          <Form.Item>
            <SortDropdown
              list={[
                {
                  label: I18N.t('分身名称'),
                  key: 'name',
                },
                {
                  label: I18N.t('打开时间'),
                  key: 'startTime',
                },
                {
                  label: I18N.t('状态'),
                  key: 'status',
                },
              ]}
              order={order}
              onChange={changeOrder}
            />
          </Form.Item>
        </Form>
      </Slot.Emit>
      <div className={'table-container'}>
        <OpeningShopListTable
          footer={() => {
            const disabled = selectedRows?.length === 0;
            return (
              <Row wrap={false} align="middle" style={{ width: '100%' }}>
                <Col flex="1" style={{ overflow: 'hidden' }}>
                  <Tooltip
                    title={disabled ? I18N.t('请至少选择一个分身') : false}
                    placement={'topLeft'}
                  >
                    <StyledOverflow
                      maxCount={'responsive'}
                      renderItem={(item) => {
                        return item.node({
                          type: 'primary',
                          ghost: true,
                          style: {
                            width: 120,
                          },
                          className: disabled ? buttonStyles?.disabled : '',
                        });
                      }}
                      renderRest={(items) => {
                        return (
                          <Dropdown
                            overlay={
                              <Menu className={styles.batchDropdownMenu} disabled={disabled}>
                                {items.map((item) => {
                                  return (
                                    <Menu.Item key={item.key}>
                                      {item.node({
                                        type: 'link',
                                        className: disabled ? buttonStyles.disabledBtnLink : '',
                                        block: true,
                                      })}
                                    </Menu.Item>
                                  );
                                })}
                              </Menu>
                            }
                          >
                            <Space>
                              <Button
                                className={disabled ? buttonStyles.disabled : ''}
                                type={'primary'}
                                icon={<IconFontIcon iconName="gengduo_24" />}
                                ghost
                                style={{ width: 22 }}
                              />
                            </Space>
                          </Dropdown>
                        );
                      }}
                      data={[
                        {
                          key: 'control',
                          node: (_props: ButtonProps = {}) => {
                            if (controlled) {
                              return (
                                <Button
                                  danger
                                  icon={<IconFontIcon iconName={'bendirengongganyu_24'} />}
                                  {..._props}
                                  onClick={() => {
                                    DMConfirm({
                                      width: 540,
                                      title: I18N.t('确定要停止群控吗？'),
                                      content: I18N.t('停止群控后将不再有主控之分'),
                                      onOk() {
                                        sendAsync('gc/stop').then(() => fetchGcDetail());
                                      },
                                    });
                                  }}
                                >
                                  <span>{I18N.t('停止群控')}</span>
                                </Button>
                              );
                            }

                            return (
                              <Button
                                {..._props}
                                className={
                                  !disabled ? buttonStyles.successBtnGhost : buttonStyles.disabled
                                }
                                icon={<IconFontIcon iconName={'bendirengongganyu_24'} />}
                                onClick={() => {
                                  if (disabled) return;
                                  startSync((layout) => {
                                    windowAlignRef.current?.changeLayout(layout);
                                  });
                                }}
                              >
                                <span>{I18N.t('开启群控')}</span>
                              </Button>
                            );
                          },
                        },
                        {
                          key: 'minimize',
                          node: (_props: ButtonProps = {}) => {
                            return (
                              <Button
                                {..._props}
                                icon={<IconFontIcon iconName={'zuixiaohua_24'} />}
                                onClick={() => {
                                  beforeOpen(I18N.t('最小化')).then(() => {
                                    // 最小化
                                    sendAsync(
                                      'browser.sync.minimizeWin',
                                      selectedRows.map((item) => item.sessionId),
                                    );
                                  });
                                }}
                              >
                                <span>{I18N.t('最小化')}</span>
                              </Button>
                            );
                          },
                        },
                        {
                          key: 'restore',
                          node: (_props: ButtonProps = {}) => {
                            return (
                              <Button
                                {..._props}
                                icon={<IconFontIcon iconName={'duibi_24'} />}
                                onClick={() => {
                                  beforeOpen(I18N.t('显示')).then(() => {
                                    // 显示
                                    sendAsync(
                                      'browser.sync.restoreWin',
                                      selectedRows.map((item) => item.sessionId),
                                    );
                                  });
                                }}
                              >
                                <span>{I18N.t('显示窗口')}</span>
                              </Button>
                            );
                          },
                        },
                        {
                          key: 'close',
                          node: (_props: ButtonProps = {}) => {
                            return (
                              <Button
                                {..._props}
                                type="primary"
                                ghost
                                danger
                                icon={<IconFontIcon iconName={'Close-Circle_24'} />}
                                onClick={() => {
                                  if (!selectedRows.length) return;
                                  if (closingRowIds.length > 0) return;
                                  beforeOpen(I18N.t('关闭')).then(() => {
                                    setClosingRowIds(selectedRows.map((r) => r.sessionId));
                                    pMinDelay(
                                      sendAsync(
                                        'browser.sync.close',
                                        selectedRows.map((r) => r.shop.id),
                                      ),
                                      1000,
                                    ).then(() => {
                                      setClosingRowIds([]);
                                    });
                                  });
                                }}
                                loading={!!closingRowIds.length}
                              >
                                <span>{I18N.t('关闭窗口')}</span>
                              </Button>
                            );
                          },
                        },
                        controlled && {
                          key: 'sync',
                          node: (_props: ButtonProps = {}) => {
                            return (
                              <Button
                                {..._props}
                                icon={<IconFontIcon iconName={'xinjianyeqian_24'} />}
                                onClick={() => {
                                  DMConfirm({
                                    width: 540,
                                    title: I18N.t('确定要统一所有浏览器分身窗口的标签吗？'),
                                    content: I18N.t(
                                      '以主控窗口为准，会将其它所有浏览器分身的标签进行统一，包括标签访问的网站、顺序、数量等等',
                                    ),

                                    onOk() {
                                      sendAsync('gc/sync_tabs');
                                    },
                                  });
                                }}
                              >
                                <span>{I18N.t('统一标签')}</span>
                              </Button>
                            );
                          },
                        },
                      ].filter(Boolean)}
                    />
                  </Tooltip>
                </Col>
                <Col flex="0 0 auto">
                  <span>
                    {I18N.t('共')}
                    {sessionsSorted.length}
                    {I18N.t('个')}
                  </span>
                </Col>
              </Row>
            );
          }}
        />
      </div>
      <div className={'tab-container'}>
        <WindowAlign
          ref={windowAlignRef}
          sessions={sessionsSorted.filter(
            (s) => selectedRows.findIndex((item) => item.sessionId === s.sessionId) !== -1,
          )}
          controlled={controlled}
        />
      </div>
    </div>
  );
};
export default WindowSync;

import React, { useCallback, useState } from 'react';

import { useModalCaller } from '@/mixins/modal';
import DMModal from '@/components/Common/Modal/DMModal';
import I18N from '@/i18n/I18N';
import { Form, Input, Radio, Space } from 'antd';
import DMFormItem from '@/components/Common/DMFormItem';
import { sendAsync } from '@/utils/ElectronUtils';
import DMConfirm, { DMLoading } from '@/components/Common/DMConfirm';

type Props = {
  localFrontendProxyMode?: 'system' | 'custom';
  localFrontendProxyUrl?: 'string';
  onSubmit: (values: any) => void;
  onClose: () => void;
};

/**
 *
 * @param props
 * @constructor
 */
const LocalFrontendProxySettingsModal: React.FC<Props> = (props) => {
  const {
    localFrontendProxyMode = 'system',
    localFrontendProxyUrl = '',
    onSubmit,
    onClose,
  } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  return (
    <DMModal
      visible
      title={I18N.t('海外IP加速设置')}
      onOk={() => {
        form.submit();
      }}
      onCancel={onClose}
      okButtonProps={{ loading }}
    >
      <Form
        form={form}
        requiredMark={false}
        initialValues={{
          localFrontendProxyMode,
          localFrontendProxyUrl,
        }}
        onFinish={(values) => {
          // 探测代理地址是否可用
          setLoading(true);
          const loadingModal = DMLoading({
            title: I18N.t('检测中'),
            content: I18N.t('正在检测指定的代理方式是否可用，请稍候...'),
          });
          sendAsync('local-frontend-proxy-test', values)
            .then((res) => {
              if (res.success) {
                DMConfirm({
                  type: 'success',
                  title: I18N.t('代理地址可用'),
                  content: I18N.t('延迟为{{delay}}ms', { delay: res.delay }),
                  onOk: () => {
                    onSubmit(values);
                    onClose();
                  },
                });
              } else {
                DMConfirm({
                  type: 'error',
                  title: I18N.t('代理地址不可用'),
                  content: I18N.t('请检查代理地址是否正确'),
                });
              }
            })
            .catch((e) => {
              DMConfirm({
                type: 'error',
                title: I18N.t('检测失败'),
                content: I18N.t('检测代理地址时发生错误：{{error}}', { error: e.message }),
              });
            })
            .finally(() => {
              setLoading(false);
              loadingModal.destroy();
            });
        }}
      >
        <DMFormItem name="localFrontendProxyMode" style={{ marginBottom: 0 }}>
          <Radio.Group>
            <Space direction="vertical" size={8}>
              <Radio value="system" style={{ lineHeight: '30px' }}>
                {I18N.t('使用系统代理')}
              </Radio>
              <Radio value="custom" style={{ lineHeight: '30px' }}>
                {I18N.t('指定代理地址')}
              </Radio>
            </Space>
          </Radio.Group>
        </DMFormItem>
        <DMFormItem shouldUpdate noStyle>
          {(f) => {
            const mode = f.getFieldValue('localFrontendProxyMode');
            if (mode !== 'custom') {
              return null;
            }
            return (
              <DMFormItem
                name="localFrontendProxyUrl"
                label={I18N.t('代理服务地址')}
                disableLabelMuted
                tooltip={
                  <>
                    <div>{I18N.t('代理服务地址格式为')}：</div>
                    <div>{I18N.t('协议://[用户名:密码@]代理地址:端口')}</div>
                    <div>{I18N.t('示例如下')}：</div>
                    <div>socks5://127.0.0.1:7890</div>
                    <div>http://127.0.0.1:10801</div>
                  </>
                }
                rules={[{ required: true }]}
                style={{ marginLeft: 24 }}
              >
                <Input placeholder={I18N.t('请输入代理服务地址')} />
              </DMFormItem>
            );
          }}
        </DMFormItem>
      </Form>
    </DMModal>
  );
};

export default LocalFrontendProxySettingsModal;

export function useLocalFrontendProxySettingsModal() {
  const modalCaller = useModalCaller(true);
  return useCallback(
    (props: Omit<Props, 'onClose'>) => {
      modalCaller({
        component: ({ close }) => <LocalFrontendProxySettingsModal {...props} onClose={close} />,
      });
    },
    [modalCaller],
  );
}
